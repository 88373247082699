import React, { useEffect, useState } from 'react'
import './how-header.css'
import './media-query-how-header.css'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../app/store'
import { language_options } from '../../../../features/languageSlice'

export const HowHeader: React.FC = () => {
  // LANGUAGE STATE
  const languageState = useSelector((state: RootState) => state.language)

  // LANGUAGE OBJECT
  const content = {
    eng: {
      h1: "LET'S FIND THE BEST WAY TO MAKE IT WORK FOR YOU",
      h2: 'We help with stretching, joint mobilization, diet planning, and practical knowledge.',
    },
    hun: {
      h1: 'MEGTALÁLOM A LEGJOBB MÓDSZERT, AMI MŰKÖDIK ÖN SZÁMÁRA',
      h2: 'Segítünk nyújtásban, ízületi mobilizácioban, étrend tervezésben, gyakorlati ismeretekben.',
    },
  }

  interface Text {
    text: {
      h1: string
      h2: string
    }
  }

  const [content_obj, setContent_obj] = useState<Text>({
    text: {
      h1: '',
      h2: '',
    },
  })

  useEffect(() => {
    if (languageState.language === language_options.eng) {
      setContent_obj({
        text: {
          h1: content.eng.h1,
          h2: content.eng.h2,
        },
      })
    }
    if (languageState.language === language_options.hun) {
      setContent_obj({
        text: {
          h1: content.hun.h1,
          h2: content.hun.h2,
        },
      })
    }
  }, [
    languageState.language,
    content.eng.h1,
    content.eng.h2,
    content.hun.h1,
    content.hun.h2,
  ])

  // Animation state
  const [h1, setH1] = useState('hhs-h1')
  const [p, setP] = useState('hhs-p')

  useEffect(() => {
    setH1('hhs-h1 show')
    const p_time = setTimeout(() => setP('hhs-p show'), 900)

    return () => {
      clearTimeout(p_time)
    }
  }, [])

  return (
    <section className={'how-header-section'}>
      <div className={'container'}>
        <h1 className={h1}>{content_obj.text.h1}</h1>
        <p className={p}>{content_obj.text.h2}</p>
      </div>
    </section>
  )
}
