import React, { useEffect, useState } from 'react'
import './how-it-works.css'
import './how-it-works-media-query.css'
import { language_options } from '../../../../features/languageSlice'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../app/store'

export const HowItWorks: React.FC = () => {
  // LANGUAGE
  const languageState = useSelector((state: RootState) => state.language)

  // LANGUAGE OBJECT
  const content = {
    eng: {
      h4: 'HOW IT WORKS',
      p1: 'Free assessment',

      p3: 'Organise sessions',
      p4: 'Execute the plan',
      p5: 'Review results',
      p6: 'Optimize',
      p7: 'Ongoing support',
      button_text: 'LEARN MORE',
    },
    hun: {
      h4: 'HOGYAN?',
      p1: 'Ingyenes értékelés',

      p3: 'Edzés megtervezése',
      p4: 'Terv kivitelezése',
      p5: 'Eredmények áttekintése',
      p6: 'Optimalizálás',
      p7: 'Folyamatos támogatás',
      button_text: 'INFORMÁCIÓ',
    },
  }

  interface Text {
    text: {
      h4: string
      p1: string

      p3: string
      p4: string
      p5: string
      p6: string
      p7: string
      button_text: string
    }
  }

  const [content_obj, setContent_obj] = useState<Text>({
    text: {
      h4: '',
      p1: '',

      p3: '',
      p4: '',
      p5: '',
      p6: '',
      p7: '',
      button_text: '',
    },
  })

  useEffect(() => {
    if (languageState.language === language_options.eng) {
      setContent_obj({
        text: {
          h4: content.eng.h4,
          p1: content.eng.p1,

          p3: content.eng.p3,
          p4: content.eng.p4,
          p5: content.eng.p5,
          p6: content.eng.p6,
          p7: content.eng.p7,
          button_text: content.eng.button_text,
        },
      })
    }
    if (languageState.language === language_options.hun) {
      setContent_obj({
        text: {
          h4: content.hun.h4,
          p1: content.hun.p1,

          p3: content.hun.p3,
          p4: content.hun.p4,
          p5: content.hun.p5,
          p6: content.hun.p6,
          p7: content.hun.p7,
          button_text: content.hun.button_text,
        },
      })
    }
  }, [
    languageState.language,
    content.hun.h4,
    content.hun.p1,

    content.hun.p3,
    content.hun.p4,
    content.hun.p5,
    content.hun.p6,
    content.hun.p7,
    content.eng.h4,
    content.eng.p1,

    content.eng.p3,
    content.eng.p4,
    content.eng.p5,
    content.eng.p6,
    content.eng.p7,
    content.hun.button_text,
    content.eng.button_text,
  ])

  return (
    <section className={'how-it-works-section'}>
      <div className={'how-it-works-01'} />
      <div className={'how-it-works-text'}>
        <h4 className={'how-it-works-h4'}>{content_obj.text.h4}</h4>
        <div className={'how-it-works-text-container'}>
          <p>{content_obj.text.p1}</p>
          <p>{content_obj.text.p3}</p>
          <p>{content_obj.text.p4}</p>
          <p>{content_obj.text.p5}</p>
          <p>{content_obj.text.p6}</p>
          <p>{content_obj.text.p7}</p>
        </div>
        <a href={'/how'} className={'how-it-works-link'} id={'how-it-work-btn'}>
          <span>{content_obj.text.button_text}</span>
        </a>
      </div>
      <div className={'how-it-works-img'}></div>
      <div className={'how-it-works-04'} />
    </section>
  )
}
