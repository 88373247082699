import React, { useRef, useState, useEffect } from 'react'
import './contact.css'
import './contact-media.css'
import email from '../../../../assets/home-page/email.jpg'
import error_img from '../../../../assets/home-page/error.jpg'
import emailjs from '@emailjs/browser'
import { language_options } from '../../../../features/languageSlice'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../app/store'

export const Contact: React.FC = () => {
  // LANGUAGE
  const languageState = useSelector((state: RootState) => state.language)

  // LANGUAGE OBJECT
  const content = {
    eng: {
      h4: "LET'S TALK!",
      p: "I'm looking forward to hearing from you.",
      pop_up_h3: 'Thank you for contacting us.',
      pop_up_p:
        'Email send to the team successfully. We will be getting back to you shortly.',
      error_up_h3: 'There was an issue sending the email.',
      error_up_p:
        'Unfortunately the email was not deliver. Please check your connections. Or try to contact directly on our contact email address.',
      form_name_label: 'Full Name',
      form_name_placeholder: 'John Smith',
      form_email_label: 'Email address',
      form_email_placeholder: 'me@example.com',
      form_message_label: 'Your message',
      form_message_placeholder: 'Hello! ...',
      button_text: 'Send',
    },
    hun: {
      h4: 'BESZÉLGESÜNK',
      p: 'Várom üzenetét egy hasznos beszélgetéshez.',
      pop_up_h3: 'Köszönjük, hogy kapcsolatba lépett velünk.',
      pop_up_p: 'E-mail küldése sikeres volt. Hamarosan jelentkezünk.',
      error_up_h3: 'Hiba történt az e-mail küldésekor.',
      error_up_p:
        'Sajnos az e-mailt nem kézbesítették. Kérjük, ellenőrizze a kapcsolatokat. Vagy próbáljon közvetlenül kapcsolatba lépni kapcsolatfelvételi e-mail címünkön.',
      form_name_label: 'Teljes név',
      form_name_placeholder: 'T Imre',
      form_email_label: 'Email cím',
      form_email_placeholder: 'én@example.com',
      form_message_label: 'Az üzeneted',
      form_message_placeholder: 'Hello! ...',
      button_text: 'Küld',
    },
  }

  interface Text {
    text: {
      h4: string
      p: string
      pop_up_h3: string
      pop_up_p: string
      error_up_h3: string
      error_up_p: string
      form_name_label: string
      form_name_placeholder: string
      form_email_label: string
      form_email_placeholder: string
      form_message_label: string
      form_message_placeholder: string
      button_text: string
    }
  }

  const [content_obj, setContent_obj] = useState<Text>({
    text: {
      h4: '',
      p: '',
      pop_up_h3: '',
      pop_up_p: '',
      error_up_h3: '',
      error_up_p: '',
      form_name_label: '',
      form_name_placeholder: '',
      form_email_label: '',
      form_email_placeholder: '',
      form_message_label: '',
      form_message_placeholder: '',
      button_text: '',
    },
  })

  useEffect(() => {
    if (languageState.language === language_options.eng) {
      setContent_obj({
        text: {
          h4: content.eng.h4,
          p: content.eng.p,
          pop_up_h3: content.eng.pop_up_h3,
          pop_up_p: content.eng.pop_up_p,
          error_up_h3: content.eng.error_up_h3,
          error_up_p: content.eng.error_up_p,
          form_name_label: content.eng.form_name_label,
          form_name_placeholder: content.eng.form_name_placeholder,
          form_email_label: content.eng.form_email_label,
          form_email_placeholder: content.eng.form_email_placeholder,
          form_message_label: content.eng.form_message_label,
          form_message_placeholder: content.eng.form_message_placeholder,
          button_text: content.eng.button_text,
        },
      })
    }
    if (languageState.language === language_options.hun) {
      setContent_obj({
        text: {
          h4: content.hun.h4,
          p: content.hun.p,
          pop_up_h3: content.hun.pop_up_h3,
          pop_up_p: content.hun.pop_up_p,
          error_up_h3: content.hun.error_up_h3,
          error_up_p: content.hun.error_up_p,
          form_name_label: content.hun.form_name_label,
          form_name_placeholder: content.hun.form_name_placeholder,
          form_email_label: content.hun.form_email_label,
          form_email_placeholder: content.hun.form_email_placeholder,
          form_message_label: content.hun.form_message_label,
          form_message_placeholder: content.hun.form_message_placeholder,
          button_text: content.hun.button_text,
        },
      })
    }
  }, [
    languageState.language,
    content.eng.h4,
    content.eng.p,
    content.eng.pop_up_h3,
    content.eng.pop_up_p,
    content.eng.error_up_h3,
    content.eng.error_up_p,
    content.eng.form_name_label,
    content.eng.form_name_placeholder,
    content.eng.form_email_label,
    content.eng.form_email_placeholder,
    content.eng.form_message_label,
    content.eng.form_message_placeholder,
    content.eng.button_text,
    content.hun.h4,
    content.hun.p,
    content.hun.pop_up_h3,
    content.hun.pop_up_p,
    content.hun.error_up_h3,
    content.hun.error_up_p,
    content.hun.form_name_label,
    content.hun.form_name_placeholder,
    content.hun.form_email_label,
    content.hun.form_email_placeholder,
    content.hun.form_message_label,
    content.hun.form_message_placeholder,
    content.hun.button_text,
  ])

  const form = useRef<any>()

  //we display any pop up
  const [open, setOpen] = useState('pop-up hidden-pop-up')
  // error state
  const [error, setError] = useState('error-up hidden-error-up')

  // reset the success pop up window after 8s
  useEffect(() => {
    function turnOff() {
      setOpen('pop-up hidden-pop-up')
    }

    let timeOut: any

    if (open === 'pop-up') {
      timeOut = setTimeout(turnOff, 8000)
    }

    return () => clearTimeout(timeOut)
  }, [open])

  // reset the error pop up window after 8s
  useEffect(() => {
    function turnOffError() {
      setError('error-up hidden-error-up')
    }

    let timeOutError: any

    if (error === 'error-up') {
      timeOutError = setTimeout(turnOffError, 8000)
    }

    return () => clearTimeout(timeOutError)
  }, [error])

  const sendEmail = (e: any) => {
    e.preventDefault()

    const service_id: string | undefined = process.env.REACT_APP_SERVICE_ID
    const template_id: string | undefined = process.env.REACT_APP_TEMPLATE_ID
    const public_key: string | undefined = process.env.REACT_APP_PUBLIC_KEY

    if (service_id && template_id && public_key) {
      console.log(form.current)
      emailjs.sendForm(service_id, template_id, form.current, public_key).then(
        (result) => {
          if (result) {
            setOpen('pop-up')
          }
        },
        (error) => {
          console.log(error.text)
          setError('error-up')
        }
      )
      e.target.reset()
    } else {
      console.error('Missing credential for email sending.')
    }
  }

  //pop up
  const pop_up = (
    <div className={open}>
      <h3>{content_obj.text.pop_up_h3}</h3>
      <p>{content_obj.text.pop_up_p}</p>
      <figure className={'img-container'}>
        <img
          className={'success-img'}
          src={email}
          alt={'Mobile display show received emails'}
        />
      </figure>
    </div>
  )

  //pop up
  const error_up = (
    <div className={error}>
      <h3>{content_obj.text.error_up_h3}</h3>
      <p>{content_obj.text.error_up_p}</p>
      <figure className={'img-container'}>
        <img
          className={'success-img'}
          src={error_img}
          alt={'Switch bord with cables'}
        />
      </figure>
    </div>
  )

  //form
  const c_form = (
    <div className={'contact-form-container'}>
      <form
        ref={form}
        onSubmit={sendEmail}
        className={'contact-form'}
        action={'#'}
      >
        <div className={'contact-field'}>
          <label htmlFor={'full-name'}>
            {content_obj.text.form_name_label}
          </label>
          <input
            className={'contact-field-field '}
            id={'full-name'}
            type={'text'}
            name={'name'}
            required
            placeholder={content_obj.text.form_name_placeholder}
          />
        </div>
        <div className={'contact-field'}>
          <label htmlFor={'email'}>{content_obj.text.form_email_label}</label>
          <input
            className={'contact-field-field'}
            id={'email'}
            type={'email'}
            name={'email'}
            required
            placeholder={content_obj.text.form_email_placeholder}
          />
        </div>

        <div className={'contact-field'}>
          <label htmlFor={'subject'}>
            {content_obj.text.form_message_label}
          </label>
          <textarea
            className={'contact-field-field text-field'}
            id={'subject'}
            name={'subject-text'}
            rows={6}
            cols={40}
            required
            placeholder={content_obj.text.form_message_placeholder}
          />
        </div>

        <div>
          <button className={'contact-btn'} type={'submit'} id={'contact-btn'}>
            <span>{content_obj.text.button_text}</span>
          </button>
        </div>
      </form>
    </div>
  )

  return (
    <section className={'contact-section'} id={'contact-form'}>
      <div className={'contact-01'} />
      <div className={'contact-text'}>
        <h4 className={'contact-h4'}>{content_obj.text.h4}</h4>
        <div className={'contact-text-container'}>
          <p>{content_obj.text.p}</p>
          <div className={'cta-container'}>
            {c_form}
            {pop_up}
            {error_up}
          </div>
        </div>
      </div>
      <div className={'contact-img'}></div>
      <div className={'contact-04'} />
    </section>
  )
}
