import React, { useCallback, useEffect, useState, Suspense } from 'react'
import './App.css'
import { ThemeProvider } from '@mui/material/styles'
import { theme } from './theme/theme'
import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom'
import ErrorPage from './pages/error/error'
import { Header } from './header/header'
import { Footer } from './footer/footer'
import { Home } from './pages/home/home'
import { Login } from './pages/login/login'
import { Register } from './pages/register/register'
import { PasswordReset } from './pages/password-reset/password-reset'
import { PasswordResetToken } from './pages/password-reset-token/password-reset-token'
import { UserInfo } from './pages/user-info/user-info'
import { BookingHome } from './pages/booking/booking-home/booking-home'
import { BookingDate } from './pages/booking/booking-date/booking-date'
import { BookingDelete } from './pages/booking/booking-delete/booking-delete'
import { UserOwnBookings } from './pages/booking/user-own-bookings/user-own-bookings'
import { BlogHome } from './pages/blog/blog-home/blog-home'
import { About } from './pages/about/about'
import { How } from './pages/how/how'
import { Results } from './pages/results/results'
import { setLanguage } from './features/languageSlice'
import { useSelector, useDispatch } from 'react-redux'
import { language_options } from './features/languageSlice'
import { RootState } from './app/store'
import { getUser, resetUser } from './features/userSlice'
import { UserState } from './features/userSlice'
import axios from 'axios'
//snack-bar
import { AlertColor } from '@mui/material/Alert'
import { CustomizedSnackbars } from './general-componenets/snack-bar/snack-bar'
import CircularIndeterminate from './general-componenets/suspens-loading/suspens-loading'
//error boundary
import ErrorFallbackUi from './general-componenets/error-fallback-ui/error-fallback-ui'
import { ErrorBoundary } from 'react-error-boundary'

//track user browser
import { browserName, browserVersion, isMobile } from 'react-device-detect'
import emailjs from '@emailjs/browser'

/** ERROR REPORT GENERATOR **/
export const error_report_generator = (
  where: string,
  error: string,
  message: string
) => {
  const service_id: string | undefined = process.env.REACT_APP_SERVICE_ID
  const template_id: string | undefined = process.env.REACT_APP_ERROR_ID
  const public_key: string | undefined = process.env.REACT_APP_PUBLIC_KEY
  //console.log(service_id, template_id, public_key)

  const getIP = () => {
    axios
      .get('https://api.ipify.org/?format=json')
      .then((result) => {
        if (service_id && template_id && public_key) {
          let templateParams = {
            ip: result.data.ip,
            where: where,
            when: new Date().toLocaleString(),
            browser: `${browserName} ${browserVersion}, mobile:${isMobile}`,
            error: error.slice(),
            message: message,
          }

          emailjs
            .send(service_id, template_id, templateParams, public_key)
            .then((result) => {
              console.log(result)
            })
            .catch((error) => {
              console.error(error)
            })
        } else {
          console.error('Missing credential for email sending.')
        }
      })
      .catch((error) => {
        if (service_id && template_id && public_key) {
          let templateParams = {
            ip: `IP ERROR: ${error.message}`,
            where: where,
            when: new Date().toLocaleString(),
            browser: `${browserName} ${browserVersion}, mobile:${isMobile}`,
            error: error.slice(),
            message: message,
          }

          emailjs
            .send(service_id, template_id, templateParams, public_key)
            .then((result) => {
              console.log(result)
            })
            .catch((error) => {
              console.error(error)
            })
        } else {
          console.error('Missing credential for email sending.')
        }
      })
  }
  getIP()
}

/** LAZY LOADED FILES **/
const ActivateAccount = React.lazy(
  () => import('./pages/activate-account/activate-account')
)
const DisclamerEng = React.lazy(
  () => import('./terms-policy-conditions/disclamer-eng')
)
const DisclamerHun = React.lazy(
  () => import('./terms-policy-conditions/disclamer-hun')
)
const PrivacyPolicyEng = React.lazy(
  () => import('./terms-policy-conditions/privacy-policy-eng')
)
const PrivacyPolicyHun = React.lazy(
  () => import('./terms-policy-conditions/privacy-policy-hun')
)
const TermsAndConditionEng = React.lazy(
  () => import('./terms-policy-conditions/terms-and-condition-eng')
)
const TermsAndConditionHun = React.lazy(
  () => import('./terms-policy-conditions/terms-and-condition-hun')
)
const BlogAdmin = React.lazy(() => import('./pages/blog/blog-admin/blog-admin'))
const FeedbackAdmin = React.lazy(
  () => import('./pages/booking/booking-admin/feedback-admin/feedback-admin')
)
const BookingAdminBooking = React.lazy(
  () =>
    import(
      './pages/booking/booking-admin/booking-admin-booking/booking-admin-booking'
    )
)
const BookingAdminTimeSlot = React.lazy(
  () =>
    import(
      './pages/booking/booking-admin/booking-admin-time-slot/booking-admin-time-slot'
    )
)
const BookingAdmin = React.lazy(
  () => import('./pages/booking/booking-admin/booking-admin')
)

type Props = {
  children: React.ReactNode
}
export const LazyLoading = ({ children }: Props) => {
  return (
    <>
      <Suspense fallback={<CircularIndeterminate />}>{children}</Suspense>
    </>
  )
}

export const breakingPoints = {
  xl: 1400,
  l: 1200,
  ml: 1100,
  m: 1000,
  md: 900,
  mdd: 800,
  mddd: 700,
  sm: 600,
  smm: 500,
  smms: 450,
  smmm: 400,
  xs: 300,
  xss: 250,
}

export interface SubLink {
  sub_route: string
  sub_name: string
  sub_id: number
}

export interface Link {
  route: string
  name: string
  id: number
  sub: null | SubLink[]
}

/* ORIGINAL
export const routesENG: Link[] = [
  { route: '/', name: 'Home', id: 0, sub: null },
  { route: '/how', name: 'How', id: 1, sub: null },
  { route: '/results', name: 'Results', id: 2, sub: null },
  { route: '/booking', name: 'Booking', id: 3, sub: null },
  { route: '/about', name: 'About', id: 4, sub: null },
  { route: '/blog', name: 'Blog', id: 5, sub: null },
  {
    route: '/account',
    name: 'Account',
    id: 6,
    sub: [
      {
        sub_route: '/account/login',
        sub_name: 'Login',
        sub_id: 0,
      },
      {
        sub_route: '/account/register',
        sub_name: 'Register',
        sub_id: 1,
      },
      {
        sub_route: '/account/info',
        sub_name: 'Account Info',
        sub_id: 2,
      },
      {
        sub_route: '/account/pass-reset',
        sub_name: 'Password Reset',
        sub_id: 3,
      },
      {
        sub_route: '/account/activate',
        sub_name: 'Resume Account',
        sub_id: 4,
      },
    ],
  },
]

export const routesHUN: Link[] = [
  { route: '/', name: 'Főoldal', id: 0, sub: null },
  { route: '/how', name: 'Hogyan', id: 1, sub: null },
  { route: '/results', name: 'Sikerek', id: 2, sub: null },
  { route: '/booking', name: 'Foglalás', id: 3, sub: null },
  { route: '/about', name: 'Rólam', id: 4, sub: null },
  { route: '/blog', name: 'Blog', id: 5, sub: null },
  {
    route: '/account',
    name: 'Fiók info',
    id: 6,
    sub: [
      {
        sub_route: '/account/login',
        sub_name: 'Belépés',
        sub_id: 0,
      },
      {
        sub_route: '/account/register',
        sub_name: 'Regisztráció',
        sub_id: 1,
      },
      {
        sub_route: '/account/info',
        sub_name: 'Felhasználói oldal',
        sub_id: 2,
      },
      {
        sub_route: '/account/pass-reset',
        sub_name: 'Jelszó visszaállítása',
        sub_id: 3,
      },
      {
        sub_route: '/account/activate',
        sub_name: 'Fiók aktiválás',
        sub_id: 4,
      },
    ],
  },
]

 */

export const routesENG: Link[] = [
  { route: '/', name: 'Home', id: 0, sub: null },
  { route: '/how', name: 'How', id: 1, sub: null },
  { route: '/results', name: 'Results', id: 2, sub: null },
  //{ route: '/booking', name: 'Booking', id: 3, sub: null },
  { route: '/about', name: 'About', id: 4, sub: null },
  //{ route: '/blog', name: 'Blog', id: 5, sub: null },
    /*
  {
    route: '/account',
    name: 'Account',
    id: 6,
    sub: [
      {
        sub_route: '/account/login',
        sub_name: 'Login',
        sub_id: 0,
      },
      {
        sub_route: '/account/register',
        sub_name: 'Register',
        sub_id: 1,
      },
      {
        sub_route: '/account/info',
        sub_name: 'Account Info',
        sub_id: 2,
      },
      {
        sub_route: '/account/pass-reset',
        sub_name: 'Password Reset',
        sub_id: 3,
      },
      {
        sub_route: '/account/activate',
        sub_name: 'Resume Account',
        sub_id: 4,
      },
    ],
  },

     */
]

export const routesHUN: Link[] = [
  { route: '/', name: 'Főoldal', id: 0, sub: null },
  { route: '/how', name: 'Hogyan', id: 1, sub: null },
  { route: '/results', name: 'Sikerek', id: 2, sub: null },
  //{ route: '/booking', name: 'Foglalás', id: 3, sub: null },
  { route: '/about', name: 'Rólam', id: 4, sub: null },
  //{ route: '/blog', name: 'Blog', id: 5, sub: null },
    /*
  {
    route: '/account',
    name: 'Fiók info',
    id: 6,
    sub: [
      {
        sub_route: '/account/login',
        sub_name: 'Belépés',
        sub_id: 0,
      },
      {
        sub_route: '/account/register',
        sub_name: 'Regisztráció',
        sub_id: 1,
      },
      {
        sub_route: '/account/info',
        sub_name: 'Felhasználói oldal',
        sub_id: 2,
      },
      {
        sub_route: '/account/pass-reset',
        sub_name: 'Jelszó visszaállítása',
        sub_id: 3,
      },
      {
        sub_route: '/account/activate',
        sub_name: 'Fiók aktiválás',
        sub_id: 4,
      },
    ],
  },

     */
]

function App() {
  //HEADER TAB STATE-----------------------------------
  const [value, setValue] = React.useState<number>(0)
  //HEADER SUB TAB STATE
  const [sub, setSub] = React.useState<number | null>(null)

  // LANGUAGE CSS CLASS STATE--------------------------
  const [languageClass, setLanguageClass] = useState('language-widget')
  const [open, setOpen] = useState(false)

  const toggleLanguageWidget = () => {
    setOpen(!open)
  }

  // SNACK-BAR STATE
  const [message, setMessage] = useState<string>('')
  const [openM, setOpenM] = useState<boolean>(false)
  const [severity, setSeverity] = useState<AlertColor>('error')

  // Redux
  const dispatch = useDispatch()
  const languageState = useSelector((state: RootState) => state.language)
  // USER STATE-------------------------
  const userState = useSelector((state: RootState) => state.user)

  // ROUTH--------------------------------------------------------------------------------------
  const routes = (userId: number, is_staff: boolean) => [
    // if userId is 0 we don't have user logged in
    {
      path: '/',
      element: <Home />,
      errorElement: <ErrorPage />,
    },
    {
      path: '/privacy',
      element:
        languageState.language === 'hun' ? (
          <LazyLoading>
            <PrivacyPolicyHun />
          </LazyLoading>
        ) : (
          <LazyLoading>
            <PrivacyPolicyEng />
          </LazyLoading>
        ),
      errorElement: <ErrorPage />,
    },
    {
      path: '/terms',
      element:
        languageState.language === 'hun' ? (
          <LazyLoading>
            <TermsAndConditionHun />
          </LazyLoading>
        ) : (
          <LazyLoading>
            <TermsAndConditionEng />
          </LazyLoading>
        ),
      errorElement: <ErrorPage />,
    },
    {
      path: '/disclaimer',
      element:
        languageState.language === 'hun' ? (
          <LazyLoading>
            <DisclamerHun />
          </LazyLoading>
        ) : (
          <LazyLoading>
            <DisclamerEng />
          </LazyLoading>
        ),
      errorElement: <ErrorPage />,
    },
    {
      path: '/how',
      element: <How />,
      errorElement: <ErrorPage />,
    },
    {
      path: '/results',
      element: <Results />,
      errorElement: <ErrorPage />,
    },
      /*
    {
      path: '/booking',
      element: <BookingHome />,
      errorElement: <ErrorPage />,
    },

       */
    {
      path: '/about',
      element: <About />,
      errorElement: <ErrorPage />,
    },
      /*
    {
      path: '/blog',
      element: <BlogHome />,
      errorElement: <ErrorPage />,
    },

       */
      /*
    {
      path: 'blog/staff',
      element:
        userId && is_staff ? (
          <LazyLoading>
            <BlogAdmin />
          </LazyLoading>
        ) : (
          <Navigate to={'/blog'} />
        ),
      errorElement: <ErrorPage />,
    },
    {
      path: '/account',
      element: userId ? (
        <Navigate to={'/account/info'} />
      ) : (
        <Navigate to={'/account/login'} />
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: '/account/login',
      element: userId ? <Navigate to={'/'} /> : <Login />, // user directed or go to log in, if user in app than redirect to log out, if no user in app go log in.
      errorElement: <ErrorPage />,
    },
    {
      path: '/account/register',
      element: <Register />, // if user in state when register log out user before register new user (back-end, front-end)
      errorElement: <ErrorPage />,
    },
    {
      path: '/account/activate',
      element: (
        <LazyLoading>
          <ActivateAccount />
        </LazyLoading>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: '/account/info',
      element: userId ? <UserInfo /> : <Navigate to={'/account/login'} />,
      errorElement: <ErrorPage />,
    },
    {
      path: '/account/pass-reset',
      element: <PasswordReset />,
      errorElement: <ErrorPage />,
    },
    {
      path: '/account/confirm/:token',
      element: <PasswordResetToken />,
      errorElement: <ErrorPage />,
    },
    {
      path: '/booking/:date',
      element: <BookingDate />,
      errorElement: <ErrorPage />,
    },
    {
      path: '/booking/delete',
      element: <BookingDelete />,
      errorElement: <ErrorPage />,
    },
    {
      path: '/booking/own',
      element: userId ? <UserOwnBookings /> : <Navigate to={'/booking'} />,
      errorElement: <ErrorPage />,
    },
    {
      path: '/booking/staff',
      element:
        userId && is_staff ? (
          <LazyLoading>
            <BookingAdmin />
          </LazyLoading>
        ) : (
          <Navigate to={'/booking'} />
        ),
      errorElement: <ErrorPage />,
    },
    {
      path: '/booking/staff/ts',
      element:
        userId && is_staff ? (
          <LazyLoading>
            <BookingAdminTimeSlot />
          </LazyLoading>
        ) : (
          <Navigate to={'/booking'} />
        ),
      errorElement: <ErrorPage />,
    },
    {
      path: '/booking/staff/booking',
      element:
        userId && is_staff ? (
          <LazyLoading>
            <BookingAdminBooking />
          </LazyLoading>
        ) : (
          <Navigate to={'/booking'} />
        ),
      errorElement: <ErrorPage />,
    },
    {
      path: '/booking/staff/feedback',
      element:
        userId && is_staff ? (
          <LazyLoading>
            <FeedbackAdmin />
          </LazyLoading>
        ) : (
          <Navigate to={'/booking'} />
        ),
      errorElement: <ErrorPage />,
    },

       */
  ]

  const router = createBrowserRouter(
    routes(userState.user.id, userState.user.is_staff)
  )

  // USER SETTING FUNCTIONS----------------------
  const setUserFunction = useCallback(
    (obj: UserState) => {
      dispatch(getUser(obj))
    },
    [dispatch]
  )

  const resetUserFunction = useCallback(() => {
    dispatch(resetUser(null))
  }, [dispatch])

  const content = {
    eng: {
      __user_m1:
        'There was an issue and we can not log you in. Please try again.',
      __user_m2:
        'There was an issue and we can not log you in. Please try again.',
      __user_error1: 'Your authentication expired. Please log in again.',
      __user_error2:
        'There is an issue with authentication. Please log in again.',
      __user_error3: 'User inactive or deleted.',
    },
    hun: {
      __user_m1:
        'Hiba történt, és nem tudjuk bejelentkezni. Kérjük, próbálja újra.',
      __user_m2:
        'Hiba történt, és nem tudjuk bejelentkezni. Kérjük, próbálja újra.',
      __user_error1: 'A hitelesítés lejárt. Kérjük, jelentkezzen be újra.',
      __user_error2:
        'Probléma van a hitelesítéssel. Kérjük, jelentkezzen be újra.',
      __user_error3: 'A felhasználó inaktív vagy törölve.',
    },
  }

  interface Text {
    text: {
      __user_m1: string
      __user_m2: string
      __user_error1: string
      __user_error2: string
      __user_error3: string
    }
  }

  const [content_obj, setContent_obj] = useState<Text>({
    text: {
      __user_m1: '',
      __user_m2: '',
      __user_error1: '',
      __user_error2: '',
      __user_error3: '',
    },
  })

  useEffect(() => {
    //console.log(languageState.language === language_options.hun)
    if (languageState.language === language_options.hun) {
      setLanguageClass(`language-widget ${language_options.hun}`)
      setContent_obj({
        text: {
          __user_m1: content.hun.__user_m1,
          __user_m2: content.hun.__user_m2,
          __user_error1: content.hun.__user_error1,
          __user_error2: content.hun.__user_error2,
          __user_error3: content.hun.__user_error3,
        },
      })
    }
    if (languageState.language === language_options.eng) {
      setLanguageClass(`language-widget ${language_options.eng}`)
      setContent_obj({
        text: {
          __user_m1: content.eng.__user_m1,
          __user_m2: content.eng.__user_m2,
          __user_error1: content.eng.__user_error1,
          __user_error2: content.eng.__user_error2,
          __user_error3: content.eng.__user_error3,
        },
      })
    }
  }, [
    languageState.language,
    content.hun.__user_m1,
    content.hun.__user_m2,
    content.hun.__user_error1,
    content.hun.__user_error2,
    content.hun.__user_error3,
    content.eng.__user_m1,
    content.eng.__user_m2,
    content.eng.__user_error1,
    content.eng.__user_error2,
    content.eng.__user_error3,
  ])

  // CHECK USER INFO IF TOKEN EXIST, TO SEE USER IS AUTHENTICATED WITH BACK END?
  // IF YES USE USER DATA IN APP. IF NOT RESET USER STATE IN APP SO NO AUTHENTICATED USER IN APP.
  /*
  useEffect(() => {
    //console.log('RENDER USER CHECK APP.TSX')
    if (userState.user.id !== 0) {
      //console.log('CALL USER')
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BACK_END_URL}/auth/user/`,
        headers: {
          Authorization: `Token ${userState.token}`,
        },
      }

      axios
        .request(config)
        .then((response) => {
          //console.log(JSON.stringify(response.data))
          if (response.data) {
            const LU: UserState = {
              user: {
                id: response.data.id,
                username: response.data.username,
                email: response.data.email,
                is_staff: response.data.is_staff,
                groups: response.data.groups,
              },
              token: userState.token,
            }
            if (LU) {
              setUserFunction(LU)
            } else {
              //console.log('THERE WAS AN ISSUE CREATING THE LOGGED IN USER OBJECT!')
              setSeverity('error')
              setOpenM(true)
              //__user_m1
              setMessage(content_obj.text.__user_m1)
            }
          } else {
            //console.log(response)
            setSeverity('error')
            setOpenM(true)
            //__user_m2
            setMessage(content_obj.text.__user_m2)
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            try {
              console.log(error.response)
              if (error.response.data.detail === 'Invalid token.') {
                //console.log('SHIT TOKEN')
                resetUserFunction()
                setSeverity('info')
                setOpenM(true)
                //__user_error1
                setMessage(content_obj.text.__user_error1)
              } else if (
                error.response.data.detail ===
                'Invalid token header. No credentials provided.'
              ) {
                resetUserFunction()
                setSeverity('info')
                setOpenM(true)
                //__user_error2
                setMessage(content_obj.text.__user_error2)
              } else if (
                error.response.data.detail === 'User inactive or deleted.'
              ) {
                resetUserFunction()
                setSeverity('error')
                setOpenM(true)
                //__user_error3
                setMessage(content_obj.text.__user_error3)
              } else {
                resetUserFunction()
                setSeverity('error')
                setOpenM(true)
                setMessage(error.message)
              }
            } catch (err) {
              //console.log(err)
              //console.log(error)
              resetUserFunction()
              setSeverity('error')
              setOpenM(true)
              setMessage(error.message)
            }
          }
        })
    }
  }, [
    content_obj.text.__user_error1,
    content_obj.text.__user_error2,
    content_obj.text.__user_error3,
    content_obj.text.__user_m1,
    content_obj.text.__user_m2,
    resetUserFunction,
    setUserFunction,
    userState.token,
    userState.user.id,
  ])

   */

  const setLanguageFunction = (lan: string) => {
    dispatch(setLanguage(lan))
  }

  /** ERROR handling from app.tsx **/
  /** if error trigger the onError function on error boundary set error to true and start a time-out for 4sec to send email only ones in a reload **/
  const [e, setE] = useState<null | NodeJS.Timeout>(null)
  const [err, setErr] = useState(false)

  const logError = (error: Error, info: { componentStack: string }) => {
    if (e === null && !err) {
      let timer = setTimeout(
        () =>
          error_report_generator(
            'from App.tsx logError function',
            info.componentStack,
            error.message
          ),
        4000
      )
      setE(timer)
      setErr(true)
    }
  }

  //console.log(window.matchMedia("(prefers-color-scheme: light)").matches)

  return (
    <div className="App">
      <ErrorBoundary FallbackComponent={ErrorFallbackUi} onError={logError}>
        <CustomizedSnackbars boolState={[openM, setOpenM, message, severity]} />
        <ThemeProvider theme={theme}>
          <div className={'relative-wrapper'}>
            <Header tabState={[value, setValue, sub, setSub]} />
            <RouterProvider router={router} />
            <Footer />
            {/*language widget*/}
            <div
              className={open ? 'open' : languageClass}
              onClick={toggleLanguageWidget}
            >
              <div className={'relative-wrapper'}>
                {open ? (
                  <>
                    <div
                      className={'options ' + language_options.hun}
                      onClick={() => setLanguageFunction(language_options.hun)}
                    />
                    <div
                      className={'options ' + language_options.eng}
                      onClick={() => setLanguageFunction(language_options.eng)}
                    />
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </ThemeProvider>
      </ErrorBoundary>
    </div>
  )
}

export default App
