import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface UserState {
  user: {
    id: number
    username: string
    email: string
    is_staff: boolean
    groups: string[]
  }
  token: string
}

const initialState: UserState = {
  user: {
    id: 0,
    username: '',
    email: '',
    is_staff: false,
    groups: [],
  },
  token: '',
}

export const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    getUser: (state, action: PayloadAction<UserState>) => {
      state.user = action.payload.user
      state.token = action.payload.token
    },
    resetUser: (state, action: PayloadAction<null>) => {
      state.user = {
        id: 0,
        username: '',
        email: '',
        is_staff: false,
        groups: [],
      }
      state.token = ''
    },
  },
})

export const { getUser, resetUser } = userSlice.actions

export default userSlice.reducer
