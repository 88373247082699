import React from 'react'
import './how.css'
import './how-media.css'
import { HowHeader } from './how-components/how-header/how-header'
import { RoadMap } from './how-components/road-map/road-map'

export const How: React.FC = () => {
  return (
    <section className={'how-section'}>
      <HowHeader />
      <RoadMap />
    </section>
  )
}
