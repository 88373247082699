import React, { useEffect, useState } from 'react'
import './hero.css'
import './hero-media-query.css'
import { language_options } from '../../../../features/languageSlice'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../app/store'

export const Hero: React.FC = () => {
  // LANGUAGE
  const languageState = useSelector((state: RootState) => state.language)

  // LANGUAGE OBJECT
  const content = {
    eng: {
      h1: 'Hello!',
      p: 'Welcome at flexytrainer.com',
      h2: '"Where Flexibility Meets Fitness!"',
    },
    hun: {
      h1: 'Hello!',
      p: 'Üdvözöljük a flexytrainer.com oldalon ',
      h2: '"Ahol a mozgékonyság találkozik a fitnesszel!"',
    },
  }

  interface Text {
    text: {
      h1: string
      p: string
      h2: string
    }
  }

  const [content_obj, setContent_obj] = useState<Text>({
    text: {
      h1: '',
      p: '',
      h2: '',
    },
  })

  useEffect(() => {
    if (languageState.language === language_options.eng) {
      setContent_obj({
        text: {
          h1: content.eng.h1,
          p: content.eng.p,
          h2: content.eng.h2,
        },
      })
    }
    if (languageState.language === language_options.hun) {
      setContent_obj({
        text: {
          h1: content.hun.h1,
          p: content.hun.p,
          h2: content.hun.h2,
        },
      })
    }
  }, [
    languageState.language,
    content.eng.h1,
    content.eng.h2,
    content.hun.h1,
    content.hun.h2,
    content.eng.p,
    content.hun.p,
  ])

  //Animation state
  const [h1, setH1] = useState('hero-h1')
  const [p, setP] = useState('hero-p')
  const [h2, setH2] = useState('hero-h2')

  useEffect(() => {
    setH1('hero-h1 show-hero')
    const p_time = setTimeout(() => setP('hero-p show-hero'), 500)
    const h2_time = setTimeout(() => setH2('hero-h2 show-hero'), 900)

    return () => {
      clearTimeout(h2_time)
      clearTimeout(p_time)
    }
  }, [])

  return (
    <section className={'section-hero'}>
      <div className={'content'}>
        <h1 className={h1}>{content_obj.text.h1}</h1>
        <p className={p}>{content_obj.text.p}</p>
        <h2 className={h2}>{content_obj.text.h2}</h2>
      </div>

      <div />

      <div className={'hero-decor-container'}>
        <div className={'hero-decor'} />
      </div>
    </section>
  )
}
