import React from 'react'
import './buttons.css'
import './buttons-media.css'

interface PropButton {
  button_text: string
  bg_color: string
  functionProp: () => void
  border_color?: string
  color?: string
}

export const GeneralButton: React.FC<PropButton> = ({
  button_text,
  bg_color,
  functionProp,
  border_color,
  color,
}) => {
  return (
    <button
      className={'--general-btn'}
      type={'button'}
      style={{
        backgroundColor: bg_color,
        border: border_color ? `1px solid ${border_color}` : undefined,
        color: color ? color : undefined,
      }}
      onClick={functionProp}
    >
      <span>{button_text}</span>
    </button>
  )
}
