import React from 'react'
import './paralex-galery-3.css'
import './paralex-galery-3-media.css'

export const ParalexGalery3: React.FC = () => {
  return (
    <section className={'section-parallax-gallery-3'}>
      <div className={'parallax-3'}>
        <div className={'para3'} />
      </div>
    </section>
  )
}
