import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { combineReducers } from 'redux'
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import logger from 'redux-logger'
import stickyDataSlice from '../features/stickyHeaderSlice'
import languageSlice from '../features/languageSlice'
import userSlice from '../features/userSlice'
import time_slot_Slice from '../features/timeSlotSlice'
import blog_post_Slice from '../features/blogPostSlice'

let middleWareList = getDefaultMiddleware({
  serializableCheck: {
    ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
  },
})

let middlewareList

if (process.env.NODE_ENV !== 'production') {
  middlewareList = [...middleWareList, logger]
} else {
  middlewareList = [...middleWareList]
}

const reducers = combineReducers({
  //user: userSlice, disabled for now <-- not in use
  stickyData: stickyDataSlice,
  language: languageSlice,
  user: userSlice,
  time_slot_list: time_slot_Slice,
  blog_post_list: blog_post_Slice,
})

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, reducers)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: middlewareList,
  devTools: process.env.NODE_ENV !== 'production',
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
