import React, { useEffect, useState } from 'react'
import './testimonial.css'
import './testimonial-media.css'
import { language_options } from '../../../../features/languageSlice'
import { RootState } from '../../../../app/store'
import { useSelector } from 'react-redux'
import g1 from '../../../../assets/home-page/testimonial-gallery-1.jpg'
import g2 from '../../../../assets/home-page/testimonial-gallery-2.jpg'
import g3 from '../../../../assets/home-page/testimonial-gallery-3.jpg'
import g4 from '../../../../assets/home-page/testimonial-gallery-4.jpg'
import g5 from '../../../../assets/home-page/testimonial-gallery-5.jpg'
import g6 from '../../../../assets/home-page/testimonial-gallery-6.jpg'
import g7 from '../../../../assets/home-page/testimonial-gallery-7.jpg'
import g8 from '../../../../assets/home-page/testimonial-gallery-8.jpg'
import g9 from '../../../../assets/home-page/testimonial-gallery-9.jpg'
import g10 from '../../../../assets/home-page/testimonial-gallery-10.jpg'
import g11 from '../../../../assets/home-page/testimonial-gallery-11.jpg'
import g12 from '../../../../assets/home-page/testimonial-gallery-12.jpg'
import g13 from '../../../../assets/home-page/testimonial-gallery-13.jpg'
import g14 from '../../../../assets/home-page/testimonial-gallery-14.jpg'
import g15 from '../../../../assets/home-page/testimonial-gallery-15.jpg'
import g16 from '../../../../assets/home-page/testimonial-gallery-16.jpg'
import show1 from '../../../../assets/home-page/testimonial-img-show-1.jpg'
import show2 from '../../../../assets/home-page/testimonial-img-show-2.jpg'
import show3 from '../../../../assets/home-page/testimonial-img-show-3.jpg'
import show4 from '../../../../assets/home-page/testimonial-img-show-4.jpg'
import show5 from '../../../../assets/home-page/testimonial-img-show-5.jpg'

export const Testimonial: React.FC = () => {
  // LANGUAGE
  const languageState = useSelector((state: RootState) => state.language)

  // LANGUAGE OBJECT
  const content = {
    eng: {
      h4_title: 'Sipos Tamás',
      p_title: 'Bodybuilder',
      p_body:
        'I wholeheartedly recommend Imi Tacsi as a personal trainer. His expertise and commitment brought outstanding results. It creates an adaptable, motivating and friendly environment. I am very grateful for the exceptional experience and the positive impact on my well-being. Thanks for your help!',
      button_text: 'LEARN MORE',
      line_up_text: 'More happy clients…',
    },
    hun: {
      h4_title: 'Sipos Tamás',
      p_title: 'Testépítő',
      p_body:
        'Szívből ajánlom Tacsi Imit személyi edzőnek. Szakértelme és elkötelezetsége kiemelkedő eredményeket hozott. Alkalmazkodó, motiváló, barátságos környezetet teremt. Nagyon hálás vagyok a kivételes élményért és a jó közérzetemre gyakorolt pozitív hatásért. Köszönöm a segítségedet!',
      button_text: 'INFORMÁCIÓ',
      line_up_text: 'Többi elégedett ügyfél…',
    },
  }

  interface Text {
    text: {
      h4_title: string
      p_title: string
      p_body: string
      button_text: string
      line_up_text: string
    }
  }

  const [content_obj, setContent_obj] = useState<Text>({
    text: {
      h4_title: '',
      p_title: '',
      p_body: '',
      button_text: '',
      line_up_text: '',
    },
  })

  useEffect(() => {
    if (languageState.language === language_options.eng) {
      setContent_obj({
        text: {
          h4_title: content.eng.h4_title,
          p_title: content.eng.p_title,
          p_body: content.eng.p_body,
          button_text: content.eng.button_text,
          line_up_text: content.eng.line_up_text,
        },
      })
    }
    if (languageState.language === language_options.hun) {
      setContent_obj({
        text: {
          h4_title: content.hun.h4_title,
          p_title: content.hun.p_title,
          p_body: content.hun.p_body,
          button_text: content.hun.button_text,
          line_up_text: content.hun.line_up_text,
        },
      })
    }
  }, [
    languageState.language,
    content.eng.h4_title,
    content.eng.p_title,
    content.eng.p_body,
    content.eng.button_text,
    content.eng.line_up_text,
    content.hun.h4_title,
    content.hun.p_title,
    content.hun.p_body,
    content.hun.button_text,
    content.hun.line_up_text,
  ])

  return (
    <section className={'section-testimonial'}>
      <div className={'testimonial-text'}>
        <div className={'testimonial-profile-container'}>
          <div>
            <div className={'profile-header'}>
              <div className={'profile-img-container'}>
                <div className={'profile-img'} />
              </div>
              <div className={'profile-title'}>
                <h4>{content_obj.text.h4_title}</h4>
                <p>{content_obj.text.p_title}</p>
              </div>
            </div>
            <div className={'profile-body'}>
              <p>{content_obj.text.p_body}</p>

              <a href={'/results'} className={'profile-link'}>
                <span>{content_obj.text.button_text}</span>
              </a>
            </div>
          </div>
          <div className={'image-show'}>
            <div className={'image-line-up'}>
              <div className={'img-show-1'}>
                <img
                  src={show1}
                  alt={'persons face'}
                  style={{ display: 'none' }}
                />
              </div>
              <div className={'img-show-2'}>
                <img
                  src={show2}
                  alt={'persons face'}
                  style={{ display: 'none' }}
                />
              </div>
              <div className={'img-show-3'}>
                <img
                  src={show3}
                  alt={'persons face'}
                  style={{ display: 'none' }}
                />
              </div>
              <div className={'img-show-4'}>
                <img
                  src={show4}
                  alt={'persons face'}
                  style={{ display: 'none' }}
                />
              </div>
              <div className={'img-show-5'}>
                <img
                  src={show5}
                  alt={'persons face'}
                  style={{ display: 'none' }}
                />
              </div>
            </div>
            <div className={'image-line-up-text'}>
              <p>{content_obj.text.line_up_text}</p>
            </div>
          </div>
        </div>
      </div>
      <div className={'testimonial-gallery'}>
        <div className={'gallery-grid'}>
          <div className={'g-img-1'}>
            <img src={g1} alt={'people work out'} style={{ display: 'none' }} />
          </div>
          <div className={'g-img-2'}>
            <img src={g2} alt={'people work out'} style={{ display: 'none' }} />
          </div>
          <div className={'g-img-3'}>
            <img src={g3} alt={'people work out'} style={{ display: 'none' }} />
          </div>
          <div className={'g-img-4'}>
            <img src={g4} alt={'people work out'} style={{ display: 'none' }} />
          </div>
          <div className={'g-img-5'}>
            <img src={g5} alt={'people work out'} style={{ display: 'none' }} />
          </div>
          <div className={'g-img-6'}>
            <img src={g6} alt={'people work out'} style={{ display: 'none' }} />
          </div>
          <div className={'g-img-7'}>
            <img src={g7} alt={'people work out'} style={{ display: 'none' }} />
          </div>
          <div className={'g-img-8'}>
            <img src={g8} alt={'people work out'} style={{ display: 'none' }} />
          </div>
          <div className={'g-img-9'}>
            <img src={g9} alt={'people work out'} style={{ display: 'none' }} />
          </div>
          <div className={'g-img-10'}>
            <img
              src={g10}
              alt={'people work out'}
              style={{ display: 'none' }}
            />
          </div>
          <div className={'g-img-11'}>
            <img
              src={g11}
              alt={'people work out'}
              style={{ display: 'none' }}
            />
          </div>
          <div className={'g-img-12'}>
            <img
              src={g12}
              alt={'people work out'}
              style={{ display: 'none' }}
            />
          </div>
          <div className={'g-img-13'}>
            <img
              src={g13}
              alt={'people work out'}
              style={{ display: 'none' }}
            />
          </div>
          <div className={'g-img-14'}>
            <img
              src={g14}
              alt={'people work out'}
              style={{ display: 'none' }}
            />
          </div>
          <div className={'g-img-15'}>
            <img
              src={g15}
              alt={'people work out'}
              style={{ display: 'none' }}
            />
          </div>
          <div className={'g-img-16'}>
            <img
              src={g16}
              alt={'people work out'}
              style={{ display: 'none' }}
            />
          </div>
        </div>
      </div>
    </section>
  )
}
