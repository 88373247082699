import React, { useEffect } from 'react'
import './error-fallback-ui.css'
import {
  //ErrorBoundary,
  FallbackProps,
  //useErrorBoundary
} from 'react-error-boundary'
import { GeneralButton } from '../buttons/buttons'

/** error component for testing the error boundary **/
export const ErrorComponent = () => {
  useEffect(() => {
    // Simulating an error condition
    const isError = true
    if (isError) {
      throw new Error('An error occurred.')
    }
  }, [])

  return <div>Error Component</div>
}

export default function ErrorFallbackUi(props: FallbackProps) {
  const { error, resetErrorBoundary } = props
  return (
    <div className={'error-fallback'}>
      <h1>Something went wrong!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>{error.message}</p>
      <GeneralButton
        button_text={'Try reload page'}
        bg_color={'green'}
        functionProp={resetErrorBoundary}
      />
    </div>
  )
}
