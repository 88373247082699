import React, { FormEvent, useEffect, useState } from 'react'
import './about.css'
import './about-media.css'
import { ATagButton } from '../../general-componenets/a-tag-button/a-tag-button'
import { AiFillFacebook, AiFillInstagram } from 'react-icons/ai'
import { useSelector } from 'react-redux'
import { RootState } from '../../app/store'
import { language_options } from '../../features/languageSlice'
import {
  FormButton,
  InputBigTextFieldWithLabel,
} from '../../general-componenets/form-elements/form-elements'
import axios from 'axios'
import { AlertColor } from '@mui/material/Alert'
import { CustomizedSnackbars } from '../../general-componenets/snack-bar/snack-bar'

export interface Feedback {
  id: string
  when: string
  public: boolean
  feed_back: string
  user: {
    id: number
    username: string
  }
}
export const About: React.FC = () => {
  // LANGUAGE STATE
  const languageState = useSelector((state: RootState) => state.language)

  // USER STATE-------------------------
  const userState = useSelector((state: RootState) => state.user)

  // SNACK-BAR STATE
  const [message, setMessage] = useState<string>('')
  const [openM, setOpenM] = useState<boolean>(false)
  const [severity, setSeverity] = useState<AlertColor>('error')

  // LANGUAGE OBJECT
  const content = {
    eng: {
      h1: 'Imre Tacsi',
      h2: 'Personal trainer and stretching specialist',
      p: 'Are you ready to reach your full potential and reach your fitness goals? Look no further. I am your dedicated and passionate personal trainer. With my experience and deep understanding of the human body, I will be your guiding light on this transformative journey. With the program I have selected, you can not only make your everyday life more comfortable and energetic, but also accelerate your development in your chosen sport. "I can\'t spare your time, but I can spare you."',
      btn: 'View my blog',
      __message: 'Thank you for your feedback!',
      _h1: 'Feedback',
      _h2: 'Your opinion is important to us! Here you can give feedback about the service, or the website.',
      _label_txt: 'Your Feedback',
      _btn_txt: 'Send feedback',
    },
    hun: {
      h1: 'Tacsi Imre',
      h2: 'Személyi edző és nyújtás specialista',
      p: 'Készen áll, hogy a teljes potenciálját kibontakoztassa és elérje fitnesz céljait? Ne keressen tovább. Én vagyok az Ön elhivatott és szenvedélyes személyi edzője. Tapasztalatommal és az emberi test mély megértésével én leszek az Ön irányadó fénye ezen az átalakuló úton. Az általam kijelölt programmal nemcsak a mindennapjait teheti kényelmesebbé és energikusabbá, hanem felgyorsíthatja a fejlődését a választott sportágában is. "Az idejét nyújtani nem tudom, de Önt igen."',
      btn: 'Nézd meg a blogom',
      __message: 'Köszönjük a visszajelzést!',
      _h1: 'Visszajelzések',
      _h2: 'Fontos számunkra az Ön véleménye! Itt tud visszajelzést adni a szolgáltatásról vagy a weboldalról.',
      _label_txt: 'Az Ön visszajelzése',
      _btn_txt: 'Küldés',
    },
  }

  interface Text {
    text: {
      h1: string
      h2: string
      p: string
      btn: string
      __message: string
      _h1: string
      _h2: string
      _label_txt: string
      _btn_txt: string
    }
  }

  const [content_obj, setContent_obj] = useState<Text>({
    text: {
      h1: '',
      h2: '',
      p: '',
      btn: '',
      __message: '',
      _h1: '',
      _h2: '',
      _label_txt: '',
      _btn_txt: '',
    },
  })

  useEffect(() => {
    if (languageState.language === language_options.eng) {
      setContent_obj({
        text: {
          h1: content.eng.h1,
          h2: content.eng.h2,
          p: content.eng.p,
          btn: content.eng.btn,
          __message: content.eng.__message,
          _h1: content.eng._h1,
          _h2: content.eng._h2,
          _label_txt: content.eng._label_txt,
          _btn_txt: content.eng._btn_txt,
        },
      })
    }
    if (languageState.language === language_options.hun) {
      setContent_obj({
        text: {
          h1: content.hun.h1,
          h2: content.hun.h2,
          p: content.hun.p,
          btn: content.hun.btn,
          __message: content.hun.__message,
          _h1: content.hun._h1,
          _h2: content.hun._h2,
          _label_txt: content.hun._label_txt,
          _btn_txt: content.hun._btn_txt,
        },
      })
    }
  }, [
    languageState.language,
    content.eng.h1,
    content.eng.h2,
    content.eng.p,
    content.eng.btn,
    content.hun.h1,
    content.hun.h2,
    content.hun.p,
    content.hun.btn,
    content.eng.__message,
    content.eng._h1,
    content.eng._h2,
    content.eng._label_txt,
    content.eng._btn_txt,
    content.hun.__message,
    content.hun._h1,
    content.hun._h2,
    content.hun._label_txt,
    content.hun._btn_txt,
  ])

  const [h1, setH1] = useState('about-content-h1')
  const [h2, setH2] = useState('about-content-h2')
  const [p, setP] = useState('about-content-p')
  const [btn, setBtn] = useState('admin-btn-c')
  const [bc, setBc] = useState('about-link-c')

  useEffect(() => {
    setH1('about-content-h1 show')
    let h2 = setTimeout(() => setH2('about-content-h2 show'), 1000)
    let btn = setTimeout(() => setBtn('admin-btn-c show'), 1500)
    let p = setTimeout(() => setP('about-content-p show'), 2000)
    let bc = setTimeout(() => setBc('about-link-c show'), 2500)

    return () => {
      clearTimeout(h2)
      clearTimeout(btn)
      clearTimeout(p)
      clearTimeout(bc)
    }
  }, [])

  // FEEDBACK LIST *******
  const [feedback_list, setFeedback_list] = useState<Feedback[]>([])

  // FEEDBACK ********
  const [feedback, setFeedback] = useState('')

  const createNewFeedback = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    let data = JSON.stringify({
      feedback: feedback,
    })

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BACK_END_URL}/feedback/create/`,
      headers: {
        Authorization: `Token ${userState.token}`,
        'Content-Type': 'application/json',
      },
      data: data,
    }

    axios
      .request(config)
      .then((response) => {
        //console.log(JSON.stringify(response.data));
        if (response.status === 201) {
          setFeedback('')
          setSeverity('success')
          setOpenM(true)
          setMessage(content_obj.text.__message)
        }
      })
      .catch((error) => {
        //console.log(error);
        if (error) {
          setSeverity('error')
          setOpenM(true)
          setMessage(error.message)
        }
      })
  }

  const getAllFeedback = () => {
    axios
      .get(`${process.env.REACT_APP_BACK_END_URL}/feedback/all-public/`)
      .then((response) => {
        //console.log(JSON.stringify(response.data));
        if (response.status === 200) {
          setFeedback_list(response.data as Feedback[])
        }
      })
      .catch((error) => {
        //console.log(error);
        if (error) {
          setSeverity('error')
          setOpenM(true)
          setMessage(error.message)
        }
      })
  }

  useEffect(() => {
    getAllFeedback()
  }, [])

  return (
    <section className={'about-section'}>
      <div className={'about-hero'}>
        <div className={'about-absolute'}>
          <div className={'about-img-c'} />
          <div className={'about-content'}>
            <h1 className={h1}>{content_obj.text.h1}</h1>
            <h2 className={h2}>{content_obj.text.h2}</h2>
            {/*
            <div className={btn}>
              <ATagButton href={'/blog'} text={content_obj.text.btn} />
            </div>
            */}
            <p className={p}>{content_obj.text.p}</p>
            <div className={bc}>
              <a
                className={'about-a-tag'}
                href={'https://www.facebook.com/profile.php?id=100087952722277'}
                rel="noreferrer"
                target={'_blank'}
              >
                <AiFillFacebook className={'about-icon-a'} />
              </a>
              <a
                className={'about-a-tag'}
                href={'https://www.instagram.com/tacsiimi/'}
                rel="noreferrer"
                target={'_blank'}
              >
                <AiFillInstagram className={'about-icon-a'} />
              </a>
            </div>
          </div>
        </div>
        <div className={'about-hero-top'} />
        <div className={'about-hero-bottom'} />
      </div>
      {/*
      <div className={'about-body'}>
        <div className={'container about-body'}>

          <h1>{content_obj.text._h1}</h1>

          <h2>{content_obj.text._h2}</h2>
          <div>
            <CustomizedSnackbars
              boolState={[openM, setOpenM, message, severity]}
            />
            <div className={'feedback-container'}>
              {feedback_list.map((item) => (
                <div key={item.id} className={'feedback-item'}>
                  <p>{item.user.username}</p>
                  <h5>{item.feed_back}</h5>
                  <p>{item.when.slice(0, 10)}</p>
                </div>
              ))}
            </div>
          </div>
          <div>
            <div className={'--form-container'}>
              <form
                onSubmit={createNewFeedback}
                action={'#'}
                className={'--form-layout'}
              >

                <InputBigTextFieldWithLabel
                  label_text={content_obj.text._label_txt}
                  id={'feedback'}
                  name={'feedback'}
                  placeholder={'...'}
                  rows={6}
                  cols={20}
                  required={true}
                  value_={feedback}
                  setValue={setFeedback}
                />

                <FormButton button_text={content_obj.text._btn_txt} />
              </form>
            </div>
          </div>
        </div>

      </div>
       */}
    </section>
  )
}
