import React, { useEffect, useState } from 'react'
import './results.css'
import './results-media.css'
import { useSelector } from 'react-redux'
import { RootState } from '../../app/store'
import { language_options } from '../../features/languageSlice'
import face from '../../assets/home-page/testimonial-img-show-3.jpg'
import progress from './assets/maxresdefault.jpg'

export const Results: React.FC = () => {
  // LANGUAGE
  const languageState = useSelector((state: RootState) => state.language)

  // LANGUAGE OBJECT
  const content = {
    eng: {
      hero: {
        h1: 'Story',
        h2: 'Check out some of the amazing results and the story behind it',
      },
      body: {
        v1: {
          name: 'Sándor Boros',
          title: 'Office worker',
          p: "If you're on the lookout for a personal trainer, I can't recommend Imre Tacsi enough! I've been training with him for a while now, and let me tell you, he is fantastic. He really cares about helping you achieve your fitness goals and tailor the workouts to suit your needs perfectly.",
          sub_title: '3 month progress',
          sub_p:
            'It was difficult for me to get by in my daily activities because of my stiff back. I was having problems with the most basic things and was taking too many pain meds just to try and fix things. After a bad period where I had to take time off work because of these problems. Finally, I decided to try to do something. A friend of mine recommended Flexytainer.com. I checked out the website and booked a session after speaking with Imi. He made a good plan and I find time every week to attend the sessions. All training sessions were private. I was the only person there, and as we progressed, Imi kept adjusting the exercises to my needs. But I felt the difference week by week, and after about the 3rd training session. My life has changed. My back was much better and I felt less pain and discomfort. I realized that this is not a miracle at all and I need to take care of my back. But thanks to Imi, now I can do it on my own, and if I need help, I know where to turn.',
          sub_p2: 'Thank you Flexytrainer.com and Imi!',
        },
      },
    },
    hun: {
      hero: {
        h1: 'Sztori',
        h2: 'Tekintse meg a lenyűgöző eredményeket és a mögöttes történetet',
      },
      body: {
        v1: {
          name: 'Boros Sándor',
          title: 'Irodai dolgozó',
          p: 'Ha személyi edzőt keresel, nem tudom eléggé ajánlani Tacsi Imrét! Már egy ideje edzek vele, és hadd mondjam el, fantasztikus. Nagyon törődik azzal, hogy segítsen elérni fitness céljait, és az edzéseket az Ön igényeinek megfelelően szabja.',
          sub_title: '3 hónapos fejlődés',
          sub_p:
            'Nehéz volt boldogulnom a mindennapi dolgaimban a merev hátam miatt. Problémáim voltak a legalapvetőbb dolgokkal, és túl sok fájdalomcsillapítót vettem be, csak hogy megpróbáljam megoldani a dolgokat. Egy rossz időszak után, amikor ezek miatt a problémák miatt időt kellett szakítanom a munkámból. Végül úgy döntöttem, hogy megpróbálok tenni valamit. Az egyik barátom ajánlotta a Flexytainer.com weboldalt. Megnéztem a honlapot, és lefoglaltam egy foglalkozást, miután beszéltem Imivel. Kialakított egy jó tervet, és hétről hétre találok időt arra, hogy részt vegyek a foglalkozásokon. Minden edzés privát volt. Én voltam az egyetlen ember, és ahogy haladtunk előre, Imi folyamatosan az én igényeimhez igazította a gyakorlatokat. De hétről hétre éreztem a különbséget, és kb a 3. edzés utan. Megváltozott az életem. A hátam sokkal jobban lett, és ritkábban éreztem fájdalmat és kényelmetlenséget. Rájöttem, hogy ez egyáltalán nem csoda, és karban kell tartanom a hátam. De hála Iminek, most már egyedül is meg tudom csinálni, és ha segítségre van szükségem, tudom, hova kell forduljak.',
          sub_p2: 'Köszönöm Flexytrainer.com és Imi!',
        },
      },
    },
  }

  interface Text {
    text: {
      hero: {
        h1: string
        h2: string
      }
      body: {
        v1: {
          name: string
          title: string
          p: string
          sub_title: string
          sub_p: string
          sub_p2: string
        }
      }
    }
  }

  const [content_obj, setContent_obj] = useState<Text>({
    text: {
      hero: {
        h1: '',
        h2: '',
      },
      body: {
        v1: {
          name: '',
          title: '',
          p: '',
          sub_title: '',
          sub_p: '',
          sub_p2: '',
        },
      },
    },
  })

  useEffect(() => {
    if (languageState.language === language_options.eng) {
      setContent_obj({
        text: {
          hero: {
            h1: content.eng.hero.h1,
            h2: content.eng.hero.h2,
          },
          body: {
            v1: {
              name: content.eng.body.v1.name,
              title: content.eng.body.v1.title,
              p: content.eng.body.v1.p,
              sub_title: content.eng.body.v1.sub_title,
              sub_p: content.eng.body.v1.sub_p,
              sub_p2: content.eng.body.v1.sub_p2,
            },
          },
        },
      })
    }
    if (languageState.language === language_options.hun) {
      setContent_obj({
        text: {
          hero: {
            h1: content.hun.hero.h1,
            h2: content.hun.hero.h2,
          },
          body: {
            v1: {
              name: content.hun.body.v1.name,
              title: content.hun.body.v1.title,
              p: content.hun.body.v1.p,
              sub_title: content.hun.body.v1.sub_title,
              sub_p: content.hun.body.v1.sub_p,
              sub_p2: content.hun.body.v1.sub_p2,
            },
          },
        },
      })
    }
  }, [
    languageState.language,
    content.eng.hero.h1,
    content.eng.hero.h2,
    content.hun.hero.h1,
    content.hun.hero.h2,
    content.eng.body.v1.name,
    content.eng.body.v1.title,
    content.eng.body.v1.p,
    content.eng.body.v1.sub_title,
    content.eng.body.v1.sub_p,
    content.eng.body.v1.sub_p2,
    content.hun.body.v1.name,
    content.hun.body.v1.title,
    content.hun.body.v1.p,
    content.hun.body.v1.sub_title,
    content.hun.body.v1.sub_p,
    content.hun.body.v1.sub_p2,
  ])

  const [hero, setHero] = useState('results-hero-absolute')

  const [fade, setFade] = useState('')

  useEffect(() => {
    let time = setTimeout(() => setHero('results-hero-absolute show'), 800)
    let fade_ = setTimeout(() => setFade('fade'), 550)

    return () => {
      clearTimeout(time)
      clearTimeout(fade_)
    }
  }, [])

  return (
    <section className={'results-section'}>
      {/*HERO*/}
      <div className={'results-hero'}>
        <div className={`results-hero-item pic1 ${fade}`} />
        <div className={`results-hero-item pic2 ${fade}`} />
        <div className={`results-hero-item pic3 ${fade}`} />
        <div className={`results-hero-item pic4 ${fade}`} />

        <div className={`results-hero-item pic5 ${fade}`} />
        <div className={`results-hero-item pic6 ${fade}`} />
        <div className={`results-hero-item pic7 ${fade}`} />
        <div className={`results-hero-item pic8 ${fade}`} />

        <div className={`results-hero-item pic9 ${fade}`} />
        <div className={`results-hero-item pic10 ${fade}`} />
        <div className={`results-hero-item pic11 ${fade}`} />
        <div className={`results-hero-item pic12 ${fade}`} />

        <div className={hero}>
          <div className={'results-hero-img-big'}>
            <div className={'container'}>
              <h1>{content_obj.text.hero.h1}</h1>
              <h2>{content_obj.text.hero.h2}</h2>
            </div>
          </div>
        </div>
      </div>
      {/*STORY*/}
      <div className={'results-body'}>
        <div className={'container'}>
          <div className={'body-header-res'}>
            <div className={'body-img-container'}>
              <figure>
                <img src={face} alt={'face'} className={'body-img-face'} />
              </figure>
            </div>
            <div className={'body-text-container'}>
              <h4>{content_obj.text.body.v1.name}</h4>
              <h5>{content_obj.text.body.v1.title}</h5>
              <p>{content_obj.text.body.v1.p}</p>
            </div>
          </div>
          <div className={'body-img-img-c'}>
            <h4 className={'body-h4'}>{content_obj.text.body.v1.sub_title}</h4>
            <figure>
              <img
                src={progress}
                alt={'man starching his back confidently'}
                className={'body-img-face'}
              />
            </figure>
            <p>{content_obj.text.body.v1.sub_p}</p>
            <p>{content_obj.text.body.v1.sub_p2}</p>
          </div>
        </div>
      </div>
    </section>
  )
}
