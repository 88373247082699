import React, { useEffect, useState } from 'react'
import './footer.css'
import './footer-media-query.css'
import logo from '../assets/logo copy 2.png'
import { FaMapMarkedAlt } from 'react-icons/fa'
import { AiFillFacebook, AiFillInstagram } from 'react-icons/ai'
import { language_options } from '../features/languageSlice'
import { useSelector } from 'react-redux'
import { RootState } from '../app/store'

export const Footer: React.FC = () => {
  const date = new Date().getFullYear()

  // LANGUAGE
  const languageState = useSelector((state: RootState) => state.language)

  // LANGUAGE OBJECT
  const content = {
    eng: {
      h4_1: 'CONTACT US',
      h4_2: 'LOCATION',
      h4_3: 'FOLLOW US',
      p1: 'flexabilitycontact@gmail.com',
      p2: "The Freezer's Gym",
      terms: 'Terms and Conditions',
      privacy: 'Privacy Policy',
      disc: 'Disclaimer',
    },
    hun: {
      h4_1: 'EMAIL CÍMÜNK',
      h4_2: 'HELYSZÍN',
      h4_3: 'KÖVESS MINKET',
      p1: 'flexabilitycontact@gmail.com',
      p2: "The Freezer's Gym",
      terms: 'Felhasználási feltételek',
      privacy: 'Adatvédelmi irányelvek',
      disc: 'Jogi nyilatkozat',
    },
  }

  interface Text {
    text: {
      h4_1: string
      h4_2: string
      h4_3: string
      p1: string
      p2: string
      terms: string
      privacy: string
      disc: string
    }
  }

  const [content_obj, setContent_obj] = useState<Text>({
    text: {
      h4_1: '',
      h4_2: '',
      h4_3: '',
      p1: '',
      p2: '',
      terms: '',
      privacy: '',
      disc: '',
    },
  })

  useEffect(() => {
    if (languageState.language === language_options.eng) {
      setContent_obj({
        text: {
          h4_1: content.eng.h4_1,
          h4_2: content.eng.h4_2,
          h4_3: content.eng.h4_3,
          p1: content.eng.p1,
          p2: content.eng.p2,
          terms: content.eng.terms,
          privacy: content.eng.privacy,
          disc: content.eng.disc,
        },
      })
    }
    if (languageState.language === language_options.hun) {
      setContent_obj({
        text: {
          h4_1: content.hun.h4_1,
          h4_2: content.hun.h4_2,
          h4_3: content.hun.h4_3,
          p1: content.hun.p1,
          p2: content.hun.p2,
          terms: content.hun.terms,
          privacy: content.hun.privacy,
          disc: content.hun.disc,
        },
      })
    }
  }, [
    languageState.language,
    content.eng.h4_1,
    content.eng.h4_2,
    content.eng.h4_3,
    content.eng.p1,
    content.eng.p2,
    content.hun.h4_1,
    content.hun.h4_2,
    content.hun.h4_3,
    content.hun.p1,
    content.hun.p2,
    content.eng.terms,
    content.eng.privacy,
    content.eng.disc,
    content.hun.terms,
    content.hun.privacy,
    content.hun.disc,
  ])

  return (
    <footer className={'footer'}>
      <div className={'footer-container grid-footer grid--2--col'}>
        <div className={'footer-logo-container'}>
          <a href={'/'}>
            <img src={logo} alt={'Flexytrainer logo'} className={'logo'} />
          </a>
        </div>
        <div className={'footer-other-link'}>
          <div className={'footer-meta'}>
            <h4>{content_obj.text.h4_1}</h4>
            <div className={'contact-container'}>
              <p>{content_obj.text.p1}</p>
            </div>
          </div>
          <div className={'footer-meta'}>
            <h4>{content_obj.text.h4_2}</h4>
            <div className={'contact-container'}>
              <p>{content_obj.text.p2}</p>
              {/*MAp link*/}
              <a
                href={
                  'https://www.google.com/maps/place/Bodybuilder+Gym/@47.5605143,19.0959738,17z/data=!3m1!4b1!4m6!3m5!1s0x4741dbda42dfe691:0xcf2c4536d9a5649e!8m2!3d47.5605143!4d19.0981625!16s%2Fg%2F1yhbl8kdh?entry=ttu'
                }
                rel="noreferrer"
                target={'_blank'}
              >
                <FaMapMarkedAlt className={'footer-icon'} />
              </a>
            </div>
          </div>
          <div className={'footer-data'}>
            <h4>{content_obj.text.h4_3}</h4>
            <div className={'link-container'}>
              <a
                href={'https://www.facebook.com/profile.php?id=100087952722277'}
                rel="noreferrer"
                target={'_blank'}
              >
                <AiFillFacebook className={'footer-icon'} />
              </a>
              <a
                href={'https://www.instagram.com/tacsiimi/'}
                rel="noreferrer"
                target={'_blank'}
              >
                <AiFillInstagram className={'footer-icon'} />
              </a>
            </div>
          </div>
        </div>
        <div className={'footer-copy-r'}>
          {/*COOKE BOT LINK*/}
          {/*
                    <script
                        id="CookieDeclaration"
                        src="https://consent.cookiebot.com/8c8ddbdc-c5c7-4591-bf9a-65991e6a0e8d/cd.js"
                        type="text/javascript"
                        async
                    ></script>
                    */}
          <a href={'/terms'}>
            <p>{content_obj.text.terms}</p>
          </a>
          <a href={'/privacy'}>
            <p>{content_obj.text.privacy}</p>
          </a>
          <a href={'/disclaimer'}>
            <p>{content_obj.text.disc}</p>
          </a>
          <p>Copyright © {date} Flexytrainer.com. All Rights Reserved.</p>
        </div>
      </div>
      {/*todo Cooke Bot declaration script*/}
      <script
        id="CookieDeclaration"
        src="https://consent.cookiebot.com/98c3cf50-5e9c-41c7-9d17-3aa47140ff76/cd.js"
        type="text/javascript"
        async
      ></script>
    </footer>
  )
}
