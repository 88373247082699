import './paralex-galery.css'
import './paralex-galery-media.css'
import React from 'react'

export const ParalexGalery1: React.FC = () => {
  return (
    <section className={'section-parallax-gallery'}>
      <div className={'parallax'}>
        <div className={'para1'} />
      </div>
    </section>
  )
}
