import React, { useEffect, useState } from 'react'
import './booking.css'
import './booking-media.css'
import { language_options } from '../../../../features/languageSlice'
import { RootState } from '../../../../app/store'
import { useSelector } from 'react-redux'

export const Booking: React.FC = () => {
  // LANGUAGE
  const languageState = useSelector((state: RootState) => state.language)

  // LANGUAGE OBJECT
  const content = {
    eng: {
      free_text: 'FREE',
      header_h4: 'EASY BOOKING',
      content_p: 'Send us a message and we will get back to you as soon as possible. You can arrange a meeting with The trainer and we can discuss your needs and how we can help you. We are looking forward to hearing from you.',
      button_text: 'LETS TALK',
    },
    hun: {
      free_text: 'INGYENES',
      header_h4: 'EGYSZERŰ FOGLALÁS',
      content_p: "Küldj nekünk üzenetet, és mihamarabb válaszolunk rá. Megbeszélhetjük az igényeidet, valamint hogy hogyan tudunk segíteni neked. Várjuk, hogy halljunk tőled!",
      button_text: 'BESZÉLGESSÜNK',
    },
  }

  interface Text {
    text: {
      free_text: string
      header_h4: string
      content_p: string
      button_text: string
    }
  }

  const [content_obj, setContent_obj] = useState<Text>({
    text: {
      free_text: '',
      header_h4: '',
      content_p: '',
      button_text: '',
    },
  })

  useEffect(() => {
    if (languageState.language === language_options.eng) {
      setContent_obj({
        text: {
          free_text: content.eng.free_text,
          header_h4: content.eng.header_h4,
          content_p: content.eng.content_p,
          button_text: content.eng.button_text,
        },
      })
    }
    if (languageState.language === language_options.hun) {
      setContent_obj({
        text: {
          free_text: content.hun.free_text,
          header_h4: content.hun.header_h4,
          content_p: content.hun.content_p,
          button_text: content.hun.button_text,
        },
      })
    }
  }, [
    languageState.language,
    content.eng.free_text,
    content.eng.header_h4,
    content.eng.content_p,
    content.eng.button_text,
    content.hun.free_text,
    content.hun.header_h4,
    content.hun.content_p,
    content.hun.button_text,
  ])

  return (
    <section className={'section-booking'}>
      <div className={'booking-free'}>
        <span>{content_obj.text.free_text}</span>
      </div>
      <div className={'booking-grid'}>
        <div className={'booking-header'}>
          <div className={'booking-h4-wrap'}>
            <h4 className={'booking-header-h4'}>
              {content_obj.text.header_h4}
            </h4>
          </div>
        </div>
        <div className={'booking-img-container-grid'}>
          <div className={'booking-img'} />
        </div>
        <div className={'booking-text-container-grid'}>
          <div className={'booking-content'}>
            <p>{content_obj.text.content_p}</p>
            <div className={'booking-vertical-container'}>
              <a href={'#contact-form'} className={'profile-link'}>
                <span>{content_obj.text.button_text}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
