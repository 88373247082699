import React, { useEffect, useState } from 'react'
import './home.css'
import './home-media-query.css'
import { Hero } from './components/hero/hero'
import { Services } from './components/services/services'
import { HowItWorks } from './components/how-it-works/how-it-works'
import { ParalexGalery1 } from './components/paralex-galery/paralex-galery'
import { ParalexGalery2 } from './components/paralex-galery-2/paralex-galery-2'
import { ParalexGalery3 } from './components/paralex-galery-3/paralex-galery-3'
import { Testimonial } from './components/testimonial/testimonial'
import { Booking } from './components/booking/booking'
import { Cta } from './components/CTA/cta'
import { Contact } from './components/contact/contact'
import { AlertColor } from '@mui/material/Alert'
import { CustomizedSnackbars } from '../../general-componenets/snack-bar/snack-bar'
import { RootState } from '../../app/store'
import { useSelector } from 'react-redux'
import { language_options } from '../../features/languageSlice'

export const Home: React.FC = () => {
  // SNACK-BAR STATE
  const [message, setMessage] = useState<string>('')
  const [openM, setOpenM] = useState<boolean>(false)
  const [severity, setSeverity] = useState<AlertColor>('error')

  // USER STATE-------------------------
  const userState = useSelector((state: RootState) => state.user)

  const languageState = useSelector((state: RootState) => state.language)

  const content = {
    eng: {
      __activate_m1: 'You are currently logged in.',
    },
    hun: {
      __activate_m1: 'Jelenleg be vagy jelentkezve.',
    },
  }

  interface Text {
    text: {
      __activate_m1: string
    }
  }

  const [content_obj, setContent_obj] = useState<Text>({
    text: {
      __activate_m1: '',
    },
  })

  useEffect(() => {
    if (languageState.language === language_options.eng) {
      setContent_obj({
        text: {
          __activate_m1: content.eng.__activate_m1,
        },
      })
    }
    if (languageState.language === language_options.hun) {
      setContent_obj({
        text: {
          __activate_m1: content.hun.__activate_m1,
        },
      })
    }
  }, [
    languageState.language,
    content.eng.__activate_m1,
    content.hun.__activate_m1,
  ])

  useEffect(() => {
    if (
      userState.user.id !== 0 &&
      content_obj.text.__activate_m1.length !== 0
    ) {
      setSeverity('info')
      setOpenM(true)
      //__activate_m1
      setMessage(content_obj.text.__activate_m1)
    }
  }, [userState.user.id, content_obj.text.__activate_m1])

  return (
    <main>
      <CustomizedSnackbars boolState={[openM, setOpenM, message, severity]} />
      <Hero />
      <Services />
      <HowItWorks />
      <ParalexGalery1 />
      <Testimonial />
      <ParalexGalery2 />
      <Booking />
      <ParalexGalery3 />
      <Cta />
      <Contact />
    </main>
  )
}
