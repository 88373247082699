import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export const language_options = {
  hun: 'hun',
  eng: 'eng',
}

interface IinitialState {
  language: string
}

const initialState: IinitialState = {
  language: language_options.hun,
}

export const languageSlice = createSlice({
  name: 'languageSlice',
  initialState,
  reducers: {
    setLanguage: (state, action: PayloadAction<string>) => {
      console.log(state)
      if (language_options.hasOwnProperty(action.payload)) {
        state.language = action.payload
      } else {
        console.error('Not valid string value send to language state in redux.')
      }
    },
  },
})

export const { setLanguage } = languageSlice.actions

export default languageSlice.reducer
