import React, { useEffect, useState } from 'react'
import './header.css'
import './header-media-query.css'
import logo from '../assets/logo copy 2.png'
import { routesENG, routesHUN, Link } from '../App'
import { language_options } from '../features/languageSlice'
import useWindowSize from '../hooks/window-size'
import { RiMenu4Line } from 'react-icons/ri'
import { RiCloseLine } from 'react-icons/ri'
import { breakingPoints } from '../App'
import { useSelector } from 'react-redux'
import { RootState } from '../app/store'
import { useDispatch } from 'react-redux'
import { toggleSticky } from '../features/stickyHeaderSlice'
import axios from 'axios'
import { AlertColor } from '@mui/material/Alert'
import { CustomizedSnackbars } from '../general-componenets/snack-bar/snack-bar'
import { resetUser } from '../features/userSlice'

interface HeaderProp {
  tabState: [
    number,
    React.Dispatch<React.SetStateAction<number>>,
    number | null,
    React.Dispatch<React.SetStateAction<number | null>>
  ]
}

export const Header: React.FC<HeaderProp> = ({
  tabState: [value, setValue, sub, setSub],
}) => {
  const { width } = useWindowSize()

  // LANGUAGE
  const languageState = useSelector((state: RootState) => state.language)
  const [routh, setRouth] = useState<Link[]>([])

  // USER STATE
  const userState = useSelector((state: RootState) => state.user)

  // SNACK-BAR STATE
  const [message, setMessage] = useState<string>('')
  const [openM, setOpenM] = useState<boolean>(false)
  const [severity, setSeverity] = useState<AlertColor>('error')

  // LANGUAGE OBJECT
  const content = {
    eng: {
      booking_button_text: 'Book',
      logout: 'Logout',
      __logout_m1: 'You successfully logged out.',
      __logout_error1: 'Failed logout.',
    },
    hun: {
      booking_button_text: 'Foglalás',
      logout: 'Kijelentkezés',
      __logout_m1: 'Sikeresen kijelentkezett.',
      __logout_error1: 'Sikertelen kijelentkezés.',
    },
  }

  // LANGUAGE STATES
  const [booking, setBooking] = useState('')
  const [log_out, setLog_out] = useState('')
  const [log_out_message, setLog_out_message] = useState('')
  const [log_out_error, setLog_out_error] = useState('')

  useEffect(() => {
    if (languageState.language === language_options.eng) {
      setRouth(routesENG)
      setBooking(content.eng.booking_button_text)
      setLog_out(content.eng.logout)
      setLog_out_message(content.eng.__logout_m1)
      setLog_out_error(content.eng.__logout_error1)
    }
    if (languageState.language === language_options.hun) {
      setRouth(routesHUN)
      setBooking(content.hun.booking_button_text)
      setLog_out(content.hun.logout)
      setLog_out_message(content.hun.__logout_m1)
      setLog_out_error(content.hun.__logout_error1)
    }
  }, [
    languageState.language,
    content.eng.booking_button_text,
    content.hun.booking_button_text,
    content.hun.logout,
    content.eng.logout,
    content.eng.__logout_m1,
    content.eng.__logout_error1,
    content.hun.__logout_m1,
    content.hun.__logout_error1,
  ])

  const stickyState = useSelector((state: RootState) => state.stickyData.sticky)
  const dispatch = useDispatch()

  const [open, setOpen] = useState(false)

  // sticky class state
  const [stick, setStick] = useState(false)

  // header will manage the display of what menu is active based on the url
  // I set the url path to '/' as the window not reload on redirect and changes not apply to nav state
  useEffect(() => {
    //console.log('RELOAD')
    if (
      userState.user.id !== 0 &&
      window.location.pathname === '/account/login'
    ) {
      //window.location.reload()
      window.location.replace('/')
    }
  }, [userState.user.id])

  useEffect(() => {
    //console.log(window.location.pathname)
    //regex to check the first /xxx/ part after the base url
    //console.log(window.location.pathname)
    if (window.location.pathname === '/') {
      if (languageState.language === language_options.eng) {
        for (let i of routesENG) {
          //console.log(i)
          if (i.route === window.location.pathname) {
            setValue(i.id)
            setSub(null)
          }
        }
      }
      if (languageState.language === language_options.hun) {
        for (let i of routesHUN) {
          //console.log(i)
          if (i.route === window.location.pathname) {
            setValue(i.id)
            setSub(null)
          }
        }
      }
    }
    //----------------------------------------------------
    const regex = /(\/[^/]+)/
    const match = regex.exec(window.location.pathname)

    //regex to check the subdomain for example: /account/login
    const regex_sub = /^\/account\/[a-zA-Z0-9-]+$/
    const match_sub = regex_sub.exec(window.location.pathname)
    //console.log(match_sub)

    if (match_sub) {
      const result_sub = match_sub[0]

      if (languageState.language === language_options.eng) {
        for (let i of routesENG) {
          if (i.route === '/account') {
            if (i.sub) {
              for (let z of i.sub) {
                if (z.sub_route === result_sub) {
                  setSub(z.sub_id)
                }
              }
            }
          }
        }
      }
      if (languageState.language === language_options.hun) {
        for (let i of routesHUN) {
          if (i.route === '/account') {
            if (i.sub) {
              for (let z of i.sub) {
                if (z.sub_route === result_sub) {
                  setSub(z.sub_id)
                }
              }
            }
          }
        }
      }
    }

    if (match) {
      const result = match[1]
      //console.log(result)

      if (languageState.language === language_options.eng) {
        for (let i of routesENG) {
          //console.log(i)
          if (i.route === result || result === `${i.route}/`) {
            setValue(i.id)
          }
        }
      }
      if (languageState.language === language_options.hun) {
        for (let i of routesHUN) {
          //console.log(i)
          if (i.route === result || result === `${i.route}/`) {
            setValue(i.id)
          }
        }
      }
    }
  })

  //TODO: edit the observers to new page

  //TODO: CREATE OBSERVER FOR OTHER BUTTONS WO IF NEED ANIMATION
  //HOME PAGE OBSERVERS
  const contactBtn: Element | null = document.getElementById('contact-btn')
  const contact_observer = new IntersectionObserver(
    (entries, observer) => {
      const ent = entries[0]
      if (!ent.isIntersecting) {
        if (contactBtn) {
          contactBtn.className = 'contact-btn'
        }
      } else {
        if (contactBtn) {
          contactBtn.className = 'contact-btn animate-contact-btn'
        }
      }
    },
    {
      root: null,
      threshold: 0,
      rootMargin: '-20px',
    }
  )

  if (contactBtn) {
    contact_observer.observe(contactBtn)
  }

  const consultationBtn: Element | null = document.getElementById('cta-btn')
  const consultation_observer = new IntersectionObserver(
    (entries, observer) => {
      const ent = entries[0]
      if (!ent.isIntersecting) {
        if (consultationBtn) {
          consultationBtn.className = 'cta-link'
        }
      } else {
        if (consultationBtn) {
          consultationBtn.className = 'cta-link show-cta-link'
        }
      }
    },
    {
      root: null,
      threshold: 0,
      rootMargin: '-20px',
    }
  )

  if (consultationBtn) {
    consultation_observer.observe(consultationBtn)
  }

  const selectHowBtn: Element | null =
    document.getElementById('how-it-work-btn')
  const how_observer = new IntersectionObserver(
    (entries, observer) => {
      const ent = entries[0]
      if (!ent.isIntersecting) {
        if (selectHowBtn) {
          selectHowBtn.className = 'how-it-works-link'
        }
      } else {
        if (selectHowBtn) {
          selectHowBtn.className = 'how-it-works-link show-how-link'
        }
      }
    },
    {
      root: null,
      threshold: 0,
      rootMargin: '-20px',
    }
  )

  if (selectHowBtn) {
    how_observer.observe(selectHowBtn)
  }

  const selectServiceBtn: Element | null =
    document.getElementById('service-btn')
  const ssb_observer = new IntersectionObserver(
    (entries, observer) => {
      const ent = entries[0]
      if (!ent.isIntersecting) {
        if (selectServiceBtn) {
          selectServiceBtn.className = 'services-link'
        }
      } else {
        if (selectServiceBtn) {
          selectServiceBtn.className = 'services-link show-s-link'
        }
      }
    },
    {
      root: null,
      threshold: 0,
      rootMargin: '-20px',
    }
  )

  if (selectServiceBtn) {
    ssb_observer.observe(selectServiceBtn)
  }

  // Observer watching the hero section scrolling out of view and set state on sticky class state
  const sectionHeroEl: Element | null = document.querySelector('.section-hero')
  const observer = new IntersectionObserver(
    function (entries) {
      const ent = entries[0]
      //console.log(ent)
      if (!ent.isIntersecting) {
        setStick(true)
      } else {
        setStick(false)
      }
    },
    {
      root: null,
      threshold: 0,
      //rootMargin: "-80px"
    }
  )
  if (sectionHeroEl) {
    observer.observe(sectionHeroEl)
  }

  // Observer watching the hero section scrolling out of view and set state on sticky class state
  const sectionHowHeader: Element | null = document.querySelector(
    '.how-header-section'
  )
  const observerHow = new IntersectionObserver(
    function (entries) {
      const ent = entries[0]
      //console.log(ent)
      if (!ent.isIntersecting) {
        setStick(true)
      } else {
        setStick(false)
      }
    },
    {
      root: null,
      threshold: 0,
      //rootMargin: "-80px"
    }
  )
  if (sectionHowHeader) {
    observerHow.observe(sectionHowHeader)
  }

  // Observer watching the hero section scrolling out of view and set state on sticky class state
  const sectionServiceHeader: Element | null = document.querySelector(
    '.section-services-header'
  )
  const observerService = new IntersectionObserver(
    function (entries) {
      const ent = entries[0]
      //console.log(ent)
      if (!ent.isIntersecting) {
        setStick(true)
      } else {
        setStick(false)
      }
    },
    {
      root: null,
      threshold: 0,
      //rootMargin: "-80px"
    }
  )
  if (sectionServiceHeader) {
    observerService.observe(sectionServiceHeader)
  }

  useEffect(() => {
    const toggleStickyState = (state: boolean) => {
      dispatch(toggleSticky(state))
    }

    if (stick) {
      toggleStickyState(stick)
    } else {
      toggleStickyState(stick)
    }
  }, [stick, dispatch])

  const toggle = () => {
    setOpen(!open)
  }

  // USER SETTING FUNCTIONS----------------------
  const resetUserFunction = () => {
    dispatch(resetUser(null))
  }

  const logout = () => {
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BACK_END_URL}/auth/logout/`,
      headers: {
        Authorization: `Token ${userState.token}`,
      },
    }

    axios
      .request(config)
      .then((response) => {
        console.log(response.status)
        if (response.status === 204) {
          // log out user and inform user
          resetUserFunction()
          setSeverity('info')
          setOpenM(true)
          //__logout_m1
          setMessage(log_out_message)
        }
      })
      .catch((error) => {
        console.log(error)
        if (error) {
          setSeverity('error')
          setOpenM(true)
          //__logout_error1
          setMessage(log_out_error)
        }
      })
  }

  const pc = (
    <header className={stickyState ? 'header sticky' : 'header'}>
      <a href={'/'}>
        <img src={logo} alt={'Flexytrainer logo'} className={'logo'} />
      </a>
      <nav className={'main-nav'}>
        <ul className={'main-nav-list'}>
          {routh.map((r, index) => (
            <li key={index}>
              {r.sub ? (
                <>
                  <button
                    className={
                      value === r.id
                        ? 'main-nav-link-btn active-link'
                        : 'main-nav-link-btn'
                    }
                  >
                    {r.name}
                  </button>
                </>
              ) : (
                <>
                  <a
                    href={r.route}
                    className={
                      value === r.id
                        ? 'main-nav-link active-link'
                        : 'main-nav-link'
                    }
                  >
                    {r.name}
                  </a>
                </>
              )}
              {r.sub ? (
                <div className={'main-nav-sub-container'}>
                  <ul
                    className={
                      stickyState ? 'main-sub-list bg' : 'main-sub-list'
                    }
                  >
                    {/*LOG OUT ONLY RENDER WHEN USER IN STATE*/}
                    {userState.user.id !== 0 ? (
                      <>
                        <button className={'logout-btn'} onClick={logout}>
                          {log_out}
                        </button>
                      </>
                    ) : null}

                    {r.sub.map((s) => (
                      <div
                        key={s.sub_name}
                        style={
                          userState.user.id !== 0 &&
                          (s.sub_name === 'Belépés' || s.sub_name === 'Login')
                            ? { display: 'none' }
                            : {}
                        }
                      >
                        <li>
                          <a
                            href={s.sub_route}
                            className={
                              sub === s.sub_id
                                ? 'main-sub-link active-link'
                                : 'main-sub-link'
                            }
                          >
                            {s.sub_name}
                          </a>
                        </li>
                      </div>
                    ))}
                  </ul>
                </div>
              ) : null}
            </li>
          ))}
          <li>
            <a href={'/#contact-form'} className={'main-nav-link nav-cta'}>
              {booking}
            </a>
          </li>
        </ul>
      </nav>
    </header>
  )

  const openMenu = (
    <div
      className={
        open ? 'mobile-open-menu' : ' mobile-open-menu mobile-closed-menu'
      }
    >
      <div className={'x-container'}>
        <RiCloseLine className={'menu-icon'} onClick={toggle} />
      </div>
      <nav className={'main-nav-open-mobile'}>
        <ul className={'main-nav-list mobile-nav'}>
          {routh.map((r, index) => (
            <li key={index} className={'relative'}>
              {r.sub ? (
                <>
                  <button
                    className={
                      value === r.id
                        ? 'main-nav-link-btn active-link font-size-bigger'
                        : 'main-nav-link-btn font-size-bigger'
                    }
                  >
                    {r.name}
                  </button>
                </>
              ) : (
                <>
                  <a
                    href={r.route}
                    className={
                      value === r.id
                        ? 'main-nav-link active-link font-size-bigger'
                        : 'main-nav-link font-size-bigger'
                    }
                  >
                    {r.name}
                  </a>
                </>
              )}
              {r.sub ? (
                <div className={'main-nav-sub-container left-150 bottom-0'}>
                  <ul className={'main-sub-list'}>
                    {/*LOG OUT ONLY RENDER WHEN USER IN STATE*/}
                    {userState.user.id !== 0 ? (
                      <>
                        <button
                          className={'logout-btn font-size-2-rem'}
                          onClick={logout}
                        >
                          {log_out}
                        </button>
                      </>
                    ) : null}

                    {r.sub.map((s) => (
                      <div
                        key={s.sub_name}
                        style={
                          userState.user.id !== 0 &&
                          (s.sub_name === 'Belépés' || s.sub_name === 'Login')
                            ? { display: 'none' }
                            : {}
                        }
                      >
                        <li>
                          <a
                            href={s.sub_route}
                            className={
                              sub === s.sub_id
                                ? 'main-sub-link active-link font-size-2-rem'
                                : 'main-sub-link font-size-2-rem'
                            }
                          >
                            {s.sub_name}
                          </a>
                        </li>
                      </div>
                    ))}
                  </ul>
                </div>
              ) : null}
            </li>
          ))}
          <li>
            <a
              href={'/booking'}
              onClick={toggle}
              className={'main-nav-link nav-cta'}
            >
              {booking}
            </a>
          </li>
        </ul>
      </nav>
    </div>
  )

  const mobile = (
    <header className={stickyState ? 'header sticky' : 'header'}>
      <a href={'/'}>
        <img src={logo} alt={'Flexytrainer logo'} className={'logo'} />
      </a>
      <nav className={'main-nav'}>
        <div>
          {open ? null : (
            <RiMenu4Line className={'menu-icon'} onClick={toggle} />
          )}
        </div>
        {openMenu}
      </nav>
    </header>
  )

  return (
    <>
      <CustomizedSnackbars boolState={[openM, setOpenM, message, severity]} />
      {width && width >= breakingPoints.ml ? pc : mobile}
    </>
  )
}
