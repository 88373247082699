import React from 'react'
import { useRouteError } from 'react-router-dom'
import './error.style.css'
import { error_report_generator } from '../../App'

export default function ErrorPage() {
  const error: any = useRouteError()
  /** When this page in come to dome it send error log ones **/
  React.useEffect(() => {
    const stackTrace = (error: any) => {
      return error.stack
    }
    let stack = stackTrace(error)
    // SEND email in 4 sec if error page is come to dome.
    let timeout = setTimeout(
      () =>
        error_report_generator(
          'from error.tsx useEffect when router error page display',
          stack,
          error.message
        ),
      4000
    )

    return () => clearTimeout(timeout)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div id="error-page" className={'error-page'}>
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  )
}
