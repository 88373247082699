import { createTheme } from '@mui/material'

const theme_one = createTheme()

export const baseTheme = {
  main_color1: '#5808bf',
  main_color1_90_tin: '#eee6f9',
  main_color1_90_shade: '#090113',
  main_color1_70_shade: '#1a0239',
  main_color2: '#14e22c',
  main_color2_70_shade: '#06440d',
  main_color2_90_shade: '#021704',
  main_color2_90_tin: 'rgba(2, 23, 4, 0.2)',
  main_color2_low_opacity: 'rgba(20, 226, 44, 0.4)',
  main_color2_low_opacity_20: 'rgba(20, 226, 44, 0.2)',
  main_color1_90_tin_low_opacity: 'rgba(238, 230, 249, 0.4)',
  main_color2_10_shade: '#12cb28',
  main_color2_20_shade: '#10b523',
  main_color2_20_tin: '#43e856',
  main_color2_40_tin: '#72ee80',
  main_color2_80_tin: '#d0f9d5',
  dark_background_color: '#212121',
  text_color_grays: '#555',
  text_color_grays_low_opacity: 'rgba(85, 85, 85, 0.9)',
  text_color_grays2: '#333',
  text_color_grays2_60_tin: '#adadad',
  text_color_grays3: '#6f6f6f',
  white: '#fff',
  gray_text: '#d8d8d8',
  white_low_opacity: 'rgba(255, 255, 255, 0.8)',
  font_family_one: 'Articulat CF',
}

export const theme = createTheme(theme_one, {
  components: {
    MuiDateCalendar: {
      styleOverrides: {
        root: {
          backgroundColor: baseTheme.white,
          border: `4px solid ${baseTheme.main_color2}`,
          borderRadius: '9px',
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          backgroundColor: baseTheme.main_color2,
          top: '30%',
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          border: `1px solid ${baseTheme.main_color2_40_tin}`,
          '&.Mui-selected': {
            backgroundColor: baseTheme.main_color2_80_tin,
            color: baseTheme.text_color_grays2,
          },
          '&.Mui-selected:hover': {
            backgroundColor: baseTheme.main_color2_80_tin,
            color: baseTheme.text_color_grays2,
          },
          '&.Mui-selected:focus': {
            backgroundColor: baseTheme.main_color2_80_tin,
            color: baseTheme.text_color_grays2,
          },
          fontWeight: 700,
          fontSize: '1rem',
        },
      },
    },
    MuiPickersYear: {
      styleOverrides: {
        yearButton: {
          border: `1px solid ${baseTheme.main_color2_40_tin}`,
          '&.Mui-selected': {
            backgroundColor: baseTheme.main_color2_80_tin,
            color: baseTheme.text_color_grays2,
          },
          '&.Mui-selected:hover': {
            backgroundColor: baseTheme.main_color2_80_tin,
            color: baseTheme.text_color_grays2,
          },
          '&.Mui-selected:focus': {
            backgroundColor: baseTheme.main_color2_80_tin,
            color: baseTheme.text_color_grays2,
          },
        },
      },
    },
    MuiDayCalendar: {
      styleOverrides: {
        weekDayLabel: {
          fontWeight: 700,
          fontSize: '1rem',
        },
        monthContainer: {
          //padding:'1.5rem 0 1.5rem 0'
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: baseTheme.dark_background_color,
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: `${baseTheme.main_color2} !important`,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          color: baseTheme.white,
          backgroundColor: baseTheme.dark_background_color,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          color: baseTheme.white,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: baseTheme.white,
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: baseTheme.dark_background_color,
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: '1.6rem',
          backgroundColor: baseTheme.text_color_grays,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: baseTheme.white,
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: baseTheme.main_color2,
        },
      },
    },
  },
  typography: {
    fontFamily: ['Articulat CF', 'sans-serif', 'serif'].join(','),
  },
})
