import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface IinitialState {
  sticky: boolean
}

const initialState: IinitialState = {
  sticky: false,
}

export const stickyDataSlice = createSlice({
  name: 'stickyDataSlice',
  initialState,
  reducers: {
    toggleSticky: (state, action: PayloadAction<boolean>) => {
      state.sticky = action.payload
    },
  },
})

export const { toggleSticky } = stickyDataSlice.actions

export default stickyDataSlice.reducer
