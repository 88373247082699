import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface Created_by {
  id: number
  username: string
}
interface Category {
  id: string
  name: string
  created: string
}

export interface IMG {
  id: string
  image: string
  blog_post: string
}

interface Comment {
  id: string
  blog_post: string
  created_by: Created_by
  created: string
  comment: string
  public: boolean
}

interface Like {
  id: string
  blog_post: string
  created_by: Created_by
  created: string
}

export interface BlogPost {
  id: string
  created_by: Created_by
  created: string
  updated: string
  public: boolean
  title: string
  sub_title: string
  text: string
  category: Category[]
  images: IMG[]
  comments: Comment[]
  likes: Like[]
}

interface IinitialState {
  blog_post_list: BlogPost[]
}

const initialState: IinitialState = {
  blog_post_list: [],
}

export const blog_post_Slice = createSlice({
  name: 'blog_post_Slice',
  initialState,
  reducers: {
    setBlogPostList: (state, action: PayloadAction<BlogPost[]>) => {
      if (state.blog_post_list.length === 0) {
        state.blog_post_list = action.payload
      } else {
        state.blog_post_list = []
        state.blog_post_list = action.payload
      }
    },
  },
})

export const { setBlogPostList } = blog_post_Slice.actions

export default blog_post_Slice.reducer
