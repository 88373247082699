import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface Training {
  id: string
  training_name: string
}
export interface TimeSlot {
  id: string
  when: string
  time_start: string
  available: string
  public: boolean
  duration: {
    id: string
    name: string
    duration_in_minutes: number
  }
  location: {
    id: string
    location_name: string
    location_address: string
  }
  training_type: Training[]
}

interface IinitialState {
  time_slot_list: TimeSlot[]
}

const initialState: IinitialState = {
  time_slot_list: [],
}

export const time_slot_Slice = createSlice({
  name: 'time_slot_Slice',
  initialState,
  reducers: {
    setTimeSlotList: (state, action: PayloadAction<TimeSlot[]>) => {
      // if list is empty then add push the items to list
      // if not empty then set it empty before push to list
      if (state.time_slot_list.length === 0) {
        state.time_slot_list = action.payload
      } else {
        state.time_slot_list = []
        state.time_slot_list = action.payload
      }
    },
  },
})

export const { setTimeSlotList } = time_slot_Slice.actions

export default time_slot_Slice.reducer
