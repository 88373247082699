import React, { useEffect, useState } from 'react'
import './road-map.css'
import './media-query-road-map.css'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../app/store'
import { language_options } from '../../../../features/languageSlice'

export const RoadMap: React.FC = () => {
  const stickyState = useSelector((state: RootState) => state.stickyData.sticky)

  // LANGUAGE STATE
  const languageState = useSelector((state: RootState) => state.language)

  // LANGUAGE OBJECT
  const content = {
    eng: {
      first: {
        h2: 'FREE ASSESSMENT',
        p:
          'To address any health or physical issue. First, we need to assess the situation.\n' +
          'Collect enough information to be built on. Find the right method and approach to maximize the impact.\n' +
          'At this point, we are establishing a starting point for your journey.',
        lnp: 'WHAT`S HAPPENING:',
        li1: 'Collect info',
        li2: 'Consider all options',
        li3: 'Decide on the starting point',
        li4: 'Personalize a starting plan',
      },
      second: {
        h2: 'PLANING AND ORGANIZING',
        p:
          'After setting the goals and a starting plan, we work with you to create a strategy.\n' +
          'Staying ahead and organizing sessions to make sure you get the best out of your time.',
        lnp: 'WHAT`S HAPPENING:',
        li1: 'Assessing what is the best for you',
        li2: 'Impact mapping',
        li3: 'Establish communication',
        li4: 'Scope definition',
      },
      third: {
        h2: 'EXECUTE THE PLAN',
        p:
          'We will carry out the plan together. You get full support and 100% attention in every session.\n' +
          'We will use this experience to build on. See what is the best practical approach for you.',
        lnp: 'WHAT`S HAPPENING:',
        li1: 'We train together',
        li2: 'Monitor the session',
        li3: 'Plan the next steps',
        li4: 'Take a way the experience',
      },
      forth: {
        h2: 'MONITORING, MEASURING, AND IMPROVING THE DIRECTION',
        p:
          'We work with you to analyze and interpret the result, designing new\n' +
          'initiatives that build on our progress. As we move forward, we\n' +
          'keep our focus on achieving the best result for you.',
        lnp: 'WHAT`S HAPPENING:',
        li1: 'Data analytics',
        li2: 'Ongoing education',
        li3: 'Optimization',
        li4: 'Ongoing support',
      },
    },
    hun: {
      first: {
        h2: 'INGYENES ÉRTÉKELÉS',
        p:
          'Bármilyen izomzati vagy tartásbeli probléma megoldására. Először is fel kell mérnünk a helyzetet.\n' +
          ' Össze gyűjteni elegendő információt, amelyre építeni lehet. Meg találni a megfelelő módszert és megközelítést a hatás maximalizálásához.\n' +
          'Ezen a ponton egy kiindulópontot hozunk létre Önek.',
        lnp: 'MI TÖRTÉNIK:',
        li1: 'Információ gyűjtés',
        li2: 'Összes lehetőség felmérése',
        li3: 'Kiindulási pontot meghatározása',
        li4: 'Személyre szabott tervet',
      },
      second: {
        h2: 'TERVEZÉS ÉS SZERVEZÉS',
        p:
          'A célok és a kiindulási terv meghatározása után Önnel együtt dolgozunk a stratégia kialakításán.\n' +
          'Az időt és a lehetőségeket figyelembe véve, igyekszünk a maximumot kihozni az idejéből.',
        lnp: 'MI TÖRTÉNIK:',
        li1: 'Felmérni, mi a legjobb az Ön számára',
        li2: 'Hatástérképezés',
        li3: 'Kommunikáció kialakítása',
        li4: 'Hatókör meghatározása',
      },
      third: {
        h2: 'TERV VÉGREHAJTÁSA',
        p:
          'A tervet közösen végrehajtjuk. Minden edzésen teljes támogatást és 100%-os figyelmet kap.\n' +
          'Ezt a tapasztalatot felhasználjuk a jövöben. Hogy, mi a legjobb gyakorlati megközelítés az Ön számára.',
        lnp: 'MI TÖRTÉNIK:',
        li1: 'Együtt edzünk',
        li2: 'Figyeljük a edzésmenetet',
        li3: 'Következő lépések tervezése',
        li4: 'Átbeszéljük az élményt',
      },
      forth: {
        h2: 'ELEMZÉS, MÉRÉS ÉS AZ IRÁNY JAVÍTÁSA',
        p: 'Önnel együtt dolgozunk az eredmény elemzésén és értelmezésében, és olyan új kezdeményezéseket tervezünk, amelyek az Ön előrehaladására építenek. Ahogy haladunk előre, továbbra is arra összpontosítunk, hogy a legjobb eredményt érjük el az Ön számára.',
        lnp: 'MI TÖRTÉNIK:',
        li1: 'Adatelemzés',
        li2: 'Folyamatos oktatás',
        li3: 'Optimalizálás',
        li4: 'Folyamatos támogatás',
      },
    },
  }

  interface Text {
    text: {
      first: {
        h2: string
        p: string
        lnp: string
        li1: string
        li2: string
        li3: string
        li4: string
      }
      second: {
        h2: string
        p: string
        lnp: string
        li1: string
        li2: string
        li3: string
        li4: string
      }
      third: {
        h2: string
        p: string
        lnp: string
        li1: string
        li2: string
        li3: string
        li4: string
      }
      forth: {
        h2: string
        p: string
        lnp: string
        li1: string
        li2: string
        li3: string
        li4: string
      }
    }
  }

  const [content_obj, setContent_obj] = useState<Text>({
    text: {
      first: {
        h2: '',
        p: '',
        lnp: '',
        li1: '',
        li2: '',
        li3: '',
        li4: '',
      },
      second: {
        h2: '',
        p: '',
        lnp: '',
        li1: '',
        li2: '',
        li3: '',
        li4: '',
      },
      third: {
        h2: '',
        p: '',
        lnp: '',
        li1: '',
        li2: '',
        li3: '',
        li4: '',
      },
      forth: {
        h2: '',
        p: '',
        lnp: '',
        li1: '',
        li2: '',
        li3: '',
        li4: '',
      },
    },
  })

  useEffect(() => {
    if (languageState.language === language_options.eng) {
      setContent_obj({
        text: {
          first: {
            h2: content.eng.first.h2,
            p: content.eng.first.p,
            lnp: content.eng.first.lnp,
            li1: content.eng.first.li1,
            li2: content.eng.first.li2,
            li3: content.eng.first.li3,
            li4: content.eng.first.li4,
          },
          second: {
            h2: content.eng.second.h2,
            p: content.eng.second.p,
            lnp: content.eng.second.lnp,
            li1: content.eng.second.li1,
            li2: content.eng.second.li2,
            li3: content.eng.second.li3,
            li4: content.eng.second.li4,
          },
          third: {
            h2: content.eng.third.h2,
            p: content.eng.third.p,
            lnp: content.eng.third.lnp,
            li1: content.eng.third.li1,
            li2: content.eng.third.li2,
            li3: content.eng.third.li3,
            li4: content.eng.third.li4,
          },
          forth: {
            h2: content.eng.forth.h2,
            p: content.eng.forth.p,
            lnp: content.eng.forth.lnp,
            li1: content.eng.forth.li1,
            li2: content.eng.forth.li2,
            li3: content.eng.forth.li3,
            li4: content.eng.forth.li4,
          },
        },
      })
    }
    if (languageState.language === language_options.hun) {
      setContent_obj({
        text: {
          first: {
            h2: content.hun.first.h2,
            p: content.hun.first.p,
            lnp: content.hun.first.lnp,
            li1: content.hun.first.li1,
            li2: content.hun.first.li2,
            li3: content.hun.first.li3,
            li4: content.hun.first.li4,
          },
          second: {
            h2: content.hun.second.h2,
            p: content.hun.second.p,
            lnp: content.hun.second.lnp,
            li1: content.hun.second.li1,
            li2: content.hun.second.li2,
            li3: content.hun.second.li3,
            li4: content.hun.second.li4,
          },
          third: {
            h2: content.hun.third.h2,
            p: content.hun.third.p,
            lnp: content.hun.third.lnp,
            li1: content.hun.third.li1,
            li2: content.hun.third.li2,
            li3: content.hun.third.li3,
            li4: content.hun.third.li4,
          },
          forth: {
            h2: content.hun.forth.h2,
            p: content.hun.forth.p,
            lnp: content.hun.forth.lnp,
            li1: content.hun.forth.li1,
            li2: content.hun.forth.li2,
            li3: content.hun.forth.li3,
            li4: content.hun.forth.li4,
          },
        },
      })
    }
  }, [
    languageState.language,
    content.eng.first.h2,
    content.eng.first.p,
    content.eng.first.lnp,
    content.eng.first.li1,
    content.eng.first.li2,
    content.eng.first.li3,
    content.eng.first.li4,
    content.eng.second.h2,
    content.eng.second.p,
    content.eng.second.lnp,
    content.eng.second.li1,
    content.eng.second.li2,
    content.eng.second.li3,
    content.eng.second.li4,
    content.eng.third.h2,
    content.eng.third.p,
    content.eng.third.lnp,
    content.eng.third.li1,
    content.eng.third.li2,
    content.eng.third.li3,
    content.eng.third.li4,
    content.eng.forth.h2,
    content.eng.forth.p,
    content.eng.forth.lnp,
    content.eng.forth.li1,
    content.eng.forth.li2,
    content.eng.forth.li3,
    content.eng.forth.li4,
    content.hun.first.h2,
    content.hun.first.p,
    content.hun.first.lnp,
    content.hun.first.li1,
    content.hun.first.li2,
    content.hun.first.li3,
    content.hun.first.li4,
    content.hun.second.h2,
    content.hun.second.p,
    content.hun.second.lnp,
    content.hun.second.li1,
    content.hun.second.li2,
    content.hun.second.li3,
    content.hun.second.li4,
    content.hun.third.h2,
    content.hun.third.p,
    content.hun.third.lnp,
    content.hun.third.li1,
    content.hun.third.li2,
    content.hun.third.li3,
    content.hun.third.li4,
    content.hun.forth.h2,
    content.hun.forth.p,
    content.hun.forth.lnp,
    content.hun.forth.li1,
    content.hun.forth.li2,
    content.hun.forth.li3,
    content.hun.forth.li4,
  ])

  return (
    <div className={'main-map'}>
      {/* first */}
      <section className={'section-road-map'}>
        <div
          className={
            stickyState
              ? 'container map-grid road-map-grid--2--col extra-margin-top'
              : 'container map-grid road-map-grid--2--col'
          }
        >
          <div className={'map-text-container'}>
            <h2>{content_obj.text.first.h2}</h2>
            <p className={'map-text'}>{content_obj.text.first.p}</p>
            <p className={'sub-text-title'}>{content_obj.text.first.lnp}</p>
            <ul className={'map-list'}>
              <li className={'map-list-item'}>{content_obj.text.first.li1}</li>
              <li className={'map-list-item'}>{content_obj.text.first.li2}</li>
              <li className={'map-list-item'}>{content_obj.text.first.li3}</li>
              <li className={'map-list-item'}>{content_obj.text.first.li4}</li>
            </ul>
          </div>
          <div className={'map-f-img-container'}>
            <div className={'map-img-container1'}></div>
          </div>
        </div>
      </section>

      {/* second */}
      <section className={'section-road-map'}>
        <div className={'container map-grid road-map-grid--2--col-right'}>
          <div className={'map-f-img-container'}>
            <div className={'map-img-container2'}></div>
          </div>
          <div className={'map-text-container right'}>
            <h2>{content_obj.text.second.h2}</h2>
            <p className={'map-text'}>{content_obj.text.second.p}</p>
            <p className={'sub-text-title'}>{content_obj.text.second.lnp}</p>
            <ul className={'map-list'}>
              <li className={'map-list-item'}>{content_obj.text.second.li1}</li>
              <li className={'map-list-item'}>{content_obj.text.second.li2}</li>
              <li className={'map-list-item'}>{content_obj.text.second.li3}</li>
              <li className={'map-list-item'}>{content_obj.text.second.li4}</li>
            </ul>
          </div>
        </div>
      </section>

      {/* third */}
      <section className={'section-road-map'}>
        <div className={'container map-grid road-map-grid--2--col'}>
          <div className={'map-text-container'}>
            <h2>{content_obj.text.third.h2}</h2>
            <p className={'map-text'}>{content_obj.text.third.p}</p>
            <p className={'sub-text-title'}>{content_obj.text.third.lnp}</p>
            <ul className={'map-list'}>
              <li className={'map-list-item'}>{content_obj.text.third.li1}</li>
              <li className={'map-list-item'}>{content_obj.text.third.li2}</li>
              <li className={'map-list-item'}>{content_obj.text.third.li3}</li>
              <li className={'map-list-item'}>{content_obj.text.third.li4}</li>
            </ul>
          </div>
          <div className={'map-f-img-container'}>
            <div className={'map-img-container3'}></div>
          </div>
        </div>
      </section>

      {/* forth */}
      <section className={'section-road-map'}>
        <div className={'container map-grid road-map-grid--2--col-right'}>
          <div className={'map-f-img-container'}>
            <div className={'map-img-container4'}></div>
          </div>
          <div className={'map-text-container right'}>
            <h2>{content_obj.text.forth.h2}</h2>
            <p className={'map-text'}>{content_obj.text.forth.p}</p>
            <p className={'sub-text-title'}>{content_obj.text.forth.lnp}</p>
            <ul className={'map-list'}>
              <li className={'map-list-item'}>{content_obj.text.forth.li1}</li>
              <li className={'map-list-item'}>{content_obj.text.forth.li2}</li>
              <li className={'map-list-item'}>{content_obj.text.forth.li3}</li>
              <li className={'map-list-item'}>{content_obj.text.forth.li4}</li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  )
}
