import * as React from 'react'
import './suspens-loading.css'
import CircularProgress from '@mui/material/CircularProgress'

export default function CircularIndeterminate() {
  return (
    <div className={'suspense-spinner'}>
      <CircularProgress />
    </div>
  )
}

export function CircularIndeterminate2() {
  return (
    <div className={'suspense-spinner2'}>
      <CircularProgress />
    </div>
  )
}
