import React, { useEffect, useState } from 'react'
import './cta.css'
import './cta-media.css'
import { language_options } from '../../../../features/languageSlice'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../app/store'

export const Cta: React.FC = () => {
  // LANGUAGE
  const languageState = useSelector((state: RootState) => state.language)

  // LANGUAGE OBJECT
  const content = {
    eng: {
      h4: 'CONSULTATION',
      p: 'Explore your options with a free consultation. I design personalized training plans and mobilization techniques to achieve your individual goals. Take the first step towards a healthier and stronger you. Book your free consultation today.',
      button_text: 'LEARN MORE',
    },
    hun: {
      h4: 'KONZULTÁCIÓ',
      p: 'Fedezze fel lehetőségeit egy ingyenes konzultációval. A személyre szabott edzésterveket és mobilizációs technikákat az egyéni céljainak eléréséhez tervezem meg. Tegye meg az első lépést egy egészségesebb és erősebb önmaga felé. Foglalja le ingyenes konzultációját még ma.',
      button_text: 'INFORMÁCIÓ',
    },
  }

  interface Text {
    text: {
      h4: string
      p: string
      button_text: string
    }
  }

  const [content_obj, setContent_obj] = useState<Text>({
    text: {
      h4: '',
      p: '',
      button_text: '',
    },
  })

  useEffect(() => {
    if (languageState.language === language_options.eng) {
      setContent_obj({
        text: {
          h4: content.eng.h4,
          p: content.eng.p,
          button_text: content.eng.button_text,
        },
      })
    }
    if (languageState.language === language_options.hun) {
      setContent_obj({
        text: {
          h4: content.hun.h4,
          p: content.hun.p,
          button_text: content.hun.button_text,
        },
      })
    }
  }, [
    languageState.language,
    content.eng.h4,
    content.eng.p,
    content.eng.button_text,
    content.hun.h4,
    content.hun.p,
    content.hun.button_text,
  ])

  return (
    <section className={'cta-section'}>
      <div className={'cta-01'} />
      <div className={'cta-img'}></div>
      <div className={'cta-text'}>
        <h4 className={'cta-h4'}>{content_obj.text.h4}</h4>
        <div className={'cta-text-container'}>
          <p>{content_obj.text.p}</p>
        </div>
        <a href={'/#contact-form'} className={'cta-link'} id={'cta-btn'}>
          <span>{content_obj.text.button_text}</span>
        </a>
      </div>
      <div className={'cta-04'} />
    </section>
  )
}
